import { Injectable } from '@angular/core';

import { ProjectV2 } from 'app/models/projectV2.model';

import { Library } from 'app/models/library.model';
//import { DatabaseService } from './database.service';

@Injectable({
  	providedIn: 'root'
})
export class ProjectV2Service {
	
    projects: ProjectV2[] = new Array();
	private _databaseService;

    constructor(
	) {}

    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
    getProjects() : ProjectV2[] {
        return this.projects;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ SETTER
    // -----------------------------------------------------------------------------------------------------
    setProjects(projects: ProjectV2[]) {
        this.projects = projects;
        if(projects == undefined)
            return;
        //GET DOWNLOAD URL !
        for (let index = 0; index < projects.length; index++) {
            const project = projects[index];            
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    createNewProject(databaseService = undefined) {
		if(databaseService != undefined){
            this._databaseService= databaseService;
        }
        // Project
        if(this.projects==undefined)
			this.projects = [];
        var newProject = new ProjectV2();
        newProject.setKey(this._databaseService.generateKey());
        //this.projects.push(newProject);
        return newProject.getKey();
    }

    deleteProject(projectKey:string){
		if(this.projects!=undefined)
        for (let i = 0; i < this.projects.length; i++) {
            if(projectKey == this.projects[i].getKey()){
                this.projects.splice(i,1);
                return;
            }
            
        }
    }
    getProject(projectKey:string){
		if(this.projects!=undefined)
        for (let i = 0; i < this.projects.length; i++) {
            if(projectKey == this.projects[i].getKey()){
                return this.projects[i];
            }
            
        }
        return null;
    }

}