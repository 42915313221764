import {  JsonProperty } from "json2typescript";
import { Event, AnimationType, TitlePosition, TitleType, EventType } from "./event.model";
import { Time } from "./time.model";


export enum SettingType {
    Recenter = 'Recenter',
    GazeVisibility = 'GazeVisibility',
    GazeSize = 'GazeSize',
    GazeSpeed = 'GazeSpeed',
    TimeLineVisibility = 'TimeLineVisibility',
    OpenApp= 'OpenApp',
    SendMessageVisibility = 'SendMessageVisibility',
    ControlVisibility = 'ControlVisibility',
    NotifyUIVisibility = 'NotifyUIVisibility',
}

export class HeadSetting {
    @JsonProperty("Type", String, true)
    Type = "Recenter";

    @JsonProperty("ArgBool", Boolean, true)
    ArgBool = false;

    @JsonProperty("ArgNumber1", Number, true)
    ArgNumber1 = 0.;

    @JsonProperty("ArgNumber2", Number, true)
    ArgNumber2 = 1;

    @JsonProperty("ArgString", String, true)
    ArgString = "";
}

export class HeadsetSetting extends Event {

    constructor(
        key?: string,
        title?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?:string,
        animate?: boolean,
        animationtype?:AnimationType,
        nbMaxOpenInScene?:number,
        nbMaxOpen?:number,
        openBy?:string[],
        titlePosition?: TitlePosition,
        settings?: HeadSetting[]
    ) {
        super(key, title, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy);

        this.icon = "es_verrou";
        this.type = EventType.HeadsetSetting;

        const tmp = [];
        if(settings!=undefined)
            for(let i=0; i< settings.length; i++)
            {
                const a = new HeadSetting();
                a.Type = settings[i].Type!=undefined? settings[i].Type : "";
                a.ArgBool = settings[i].ArgBool !=undefined? settings[i].ArgBool : false;
                a.ArgNumber1 = settings[i].ArgNumber1 !=undefined?  settings[i].ArgNumber1 :0;
                a.ArgNumber2 = settings[i].ArgNumber2 !=undefined?  settings[i].ArgNumber2 :1;
                a.ArgString = settings[i].ArgString !=undefined?  settings[i].ArgString : "";
                tmp.push(a);
            }
        this.properties = {
            Parameters: tmp
        };
    }

}