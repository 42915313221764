import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";

import { from } from "rxjs";
import {
    setPersistence,
    signInWithEmailAndPassword,
    browserLocalPersistence,
    signOut,
    createUserWithEmailAndPassword,
    updateProfile,
    updatePassword,
    sendPasswordResetEmail,
    confirmPasswordReset,
    getAuth,
    signInWithPopup,
    GoogleAuthProvider,
    FacebookAuthProvider,
    OAuthProvider,
    getAdditionalUserInfo,
} from "firebase/auth";

@Injectable()
export class AuthUserService {
    private _authenticated = false;
    private _newUser = false;
    private _lastErrorCode = "";

    public get authenticated(): boolean {
        return this._authenticated;
    }
    public set authenticated(value: boolean) {
        this._authenticated = value;
    }

    public get newUser(): boolean {
        return this._newUser;
    }

    public get lastErrorCode(): string {
        return this._lastErrorCode;
    }

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient) {}

    auth;
    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    init(auth) {
        this.auth = auth;
    }
    /**
     * Forgot password
     *
     * @param email
     */
    forgotPassword(email: string): Observable<any> {
        return this._httpClient.post("api/auth/forgot-password", email);
    }

    /**
     * Reset password
     *
     * @param password
     */
    resetPassword(password: string): Observable<any> {
        return this._httpClient.post("api/auth/reset-password", password);
    }

    /**
     * Sign in
     *
     * @param credentials
     */
    signIn(
        email: string,
        password: string,
        rememberMe: boolean,
    ): Observable<any> {
        // Throw error, if the user is already logged in
        if (this._authenticated) {
            return throwError("User is already logged in.");
        }

        return from(
            new Promise((resolve, reject) => {
                setPersistence(this.auth, browserLocalPersistence).then(() =>
                    signInWithEmailAndPassword(this.auth, email, password).then(
                        () => {
                            // Set the authenticated flag to true
                            this._authenticated = true;
                            resolve(true);
                        },
                        (error) => {
                            resolve(error);
                        },
                    ),
                );
            }),
        );
    }

    /**
     * Sign out
     */
    signOut(): Observable<any> {
        return from(
            new Promise((resolve, reject) => {
                signOut(this.auth).then(
                    () => {
                        this._authenticated = false;
                        resolve(true);
                    },
                    (error) => {
                        reject(error);
                    },
                );
            }),
        );
    }

    signInWithMicrosoft() {
        const provider = new OAuthProvider("microsoft.com");
        return signInWithPopup(this.auth, provider)
            .then((result) => {
                const credential = OAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;
                const details = getAdditionalUserInfo(result);
                this._newUser = details.isNewUser;
                this._authenticated = true;
            })
            .catch((error) => {
                this._lastErrorCode = error.code;
                console.log("error");
                console.log(error);
                // ...
            });
    }

    signInWithGoogle() {
        console.log("signInWithGoogle");
        console.log(this.auth);
        if(this.auth == undefined){

        }
        const provider = new GoogleAuthProvider();
        return signInWithPopup(this.auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential =
                    GoogleAuthProvider.credentialFromResult(result);

                // This gives you a Google Access Token. You can use it to access the Google API.
                //const credential = GoogleAuthProvider.credentialFromResult(result);
                //const token = credential.accessToken;
                // The signed-in user info.
                const details = getAdditionalUserInfo(result);
                this._newUser = details.isNewUser;
                this._authenticated = true;
                console.log("result");
                // IdP data available using getAdditionalUserInfo(result)
                // ...
            })
            .catch((error) => {
                console.error(error);
                this._lastErrorCode = error.code;

                // ...
            });
    }

    signInWithFacebook() {
        const provider = new FacebookAuthProvider();
        return signInWithPopup(this.auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                const credential =
                    FacebookAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const details = getAdditionalUserInfo(result);
                this._newUser = details.isNewUser;
                this._authenticated = true;

                // IdP data available using getAdditionalUserInfo(result)
                // ...
            })
            .catch((error) => {
                this._lastErrorCode = error.code;

                // ...
            });
    }

    signInWithApple() {
        const provider = new OAuthProvider("apple.com");
        provider.setCustomParameters({
            // Localize the Apple authentication screen in French.
            locale: "fr",
        });

        return signInWithPopup(this.auth, provider)
            .then((result) => {
                console.log("result");
                console.log(result);
                // The signed-in user info.
                const details = getAdditionalUserInfo(result);
                this._newUser = details.isNewUser;

                // Apple credential
                const credential = OAuthProvider.credentialFromResult(result);
                const accessToken = credential.accessToken;
                const idToken = credential.idToken;

                this._authenticated = true;

                // IdP data available using getAdditionalUserInfo(result)
                // ...
            })
            .catch((error) => {
                console.log("error");
                console.log(error);
                this._lastErrorCode = error.code;

                // ...
            });
    }

    /**
     * Check the authentication status
     */
    check(): Observable<boolean> {
        // Check if the user is logged in
        if (this._authenticated) {
            return of(true);
        }

        return of(false);
    }

    createNewUser(email: string, password: string, username: string) {
        return new Promise((resolve, reject) => {
            createUserWithEmailAndPassword(this.auth, email, password).then(
                () => {
                    updateProfile(this.auth.currentUser, {
                        displayName: username,
                    });
                    this._authenticated = true;
                    resolve(undefined);
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }

    changePassword(newPassword: string) {
        const user = this.auth.currentUser;
        return new Promise((resolve, reject) => {
            updatePassword(user, newPassword).then(
                () => {
                    resolve(undefined);
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }

    sendResetPasswordEmail(email: string) {
        return new Promise((resolve, reject) => {
            sendPasswordResetEmail(this.auth, email).then(
                () => {
                    resolve(undefined);
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }

    passwordReset(code: string, newPassword: string) {
        return new Promise((resolve, reject) => {
            confirmPasswordReset(this.auth, code, newPassword).then(
                () => {
                    resolve(undefined);
                },
                (error) => {
                    reject(error);
                },
            );
        });
    }

    getUID() {
        return this.auth.currentUser.uid;
    }

    getEmail() {
        return this.auth.currentUser.email;
    }
}
