import firebase from "firebase/compat/app";
import"firebase/compat/storage";

export enum DlType{
	Data = "Data",
	DataLow = "DataLow",
	Preview ="Preview",
	Image = "Image",
	Audio = "Audio",
	Logo = "Logo"
}
export class Download {

	ProjectKey: string;
	EnvironmentKey: string;
	InteractionKey: string;
	ContentKey: string;
	File: File;
	Type: DlType;
	UploadProgress: { key: string, inProgress: boolean, progress: number, size: number, lang : string|undefined}
	Task : any;//firebase.storage.UploadTask;

	constructor(
		ProjectKey: string,
		EnvironmentKey: string,
		InteractionKey: string,
		ContentKey: string,
		file: any,
		Type: DlType,
		filesize: number,
		code : string = undefined
		) {
			this.ProjectKey = ProjectKey;
			this.EnvironmentKey = EnvironmentKey;
			this.InteractionKey = InteractionKey;
			this.ContentKey = ContentKey;
			this.File = file;
			this.Type = Type;
			this.UploadProgress =  { key: ContentKey, inProgress: true, progress: 0, size : 0 , lang : code};
		}

    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
	getProjectKey() 	        : string { return this.ProjectKey; }
	getEnvironmentKey() 		: string { return this.EnvironmentKey; }
	getInteractionKey() 		: string { return this.InteractionKey; }
	getFile() 		            : any { return this.File; }
	getType() 					: DlType { return this.Type; }
	getUploadProgress() 		: any { return this.UploadProgress; }

	// -----------------------------------------------------------------------------------------------------
	// @ SETTER
	// -----------------------------------------------------------------------------------------------------
	setProjectKey(ProjectKey : string)				{ this.ProjectKey = ProjectKey; }
	setEnvironmentKey(EnvironmentKey : string)		{ this.EnvironmentKey = EnvironmentKey; }
	setInteractionKey(InteractionKey : string) 		{ this.InteractionKey = InteractionKey; }
	setFile(file : any) 							{ this.File = file; }
	setType(Type : DlType) 							{ this.Type = Type; }
	setUploadProgress(UploadProgress : any)			{ this.UploadProgress = UploadProgress; }

}