import { JsonObject, JsonProperty } from "json2typescript";
import { Environment } from "./environment.model";
import { Interface } from './interface.model';

@JsonObject("ProjectV1")
export class Project {

	@JsonProperty("Key", String, true)
	Key: string = "";
	@JsonProperty("Environments", [Environment], true)
	Environments : Environment[] = [];
	@JsonProperty("Interface", Interface, true)
	Interface: Interface = new Interface();
	@JsonProperty("LastUpdate", String, true)
	LastUpdate: string = Date.now().toString();

	constructor() {}

	// -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
	getKey() 			: string { return this.Key; }
	getEnvironments() 	: Environment[] { return this.Environments; }
	getInterface() 		: Interface { return this.Interface; }
	getLastUpdate() 	: string { return this.LastUpdate; }

	// -----------------------------------------------------------------------------------------------------
	// @ SETTER
	// -----------------------------------------------------------------------------------------------------
	setKey(key: string) 							{ this.Key = key; }
	setEnvironments(environments:  Environment[])	{ this.Environments = environments; }
	setInterface(interf: Interface) 				{ this.Interface = interf; }
	setLastUpdate(lastUpdate: string) 				{ this.LastUpdate = lastUpdate; }

	// -----------------------------------------------------------------------------------------------------
	// @ PUBLIC METHODS
	// -----------------------------------------------------------------------------------------------------
	addEnvironment(environment: Environment) {
		this.Environments.push(environment);
	}
}

