/* eslint-disable @typescript-eslint/no-empty-function */
import { AuthService } from 'app/core/auth/auth.service';
import { DatabaseService } from 'app/services/database.service';
import { Component, HostListener, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/';
import { NavigationService } from './core/navigation/navigation.service';
//import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
//import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
//import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';


import { environment } from 'environments/environment';


import { NotificationsService } from "app/layout/common/notifications/notifications.service";
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [
        RouterOutlet,


    ],
})
export class AppComponent implements OnInit, OnDestroy
{

    @HostListener('window:beforeunload', [ '$event' ])
    beforeUnloadHander(event) {

        if(this._authService.authenticated){
            this._databaseService.disconnectUser();
        }
    }
    fuseConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {TranslateService} _translateService
     * @param {Platform} _platform
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _authService: AuthService,
        private _databaseService : DatabaseService,
        private _navigationService: NavigationService,
        //private _fuseSidebarService: FuseSidebarService,
        //private _fuseSplashScreenService: FuseSplashScreenService,
        //private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        //private _translateService: TranslateService,
        private _platform: Platform,
        private _router: Router,

        private _notificationsService: NotificationsService)
    {

        // Register the navigation to the service
        //this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        //this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        //this._translateService.addLangs(['fr', 'en']);

        // Set the default language
        //this._translateService.setDefaultLang('fr');

        // Set the navigation translations
        //this._fuseTranslationLoaderService.loadTranslations(navigationFrench, navigationEnglish);

        // Use a language
        //this._translateService.use('fr');

        //Detect Device
        //this.detectDevice();

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        /**
         * -----------------------------------------------------------------------------------------------------
         * Firebase
         * -----------------------------------------------------------------------------------------------------
         */
        // Your web app's Firebase configuration
       /* const firebaseConfig = {
            apiKey: environment.firebase.apiKey,
            authDomain: environment.firebase.authDomain,
            databaseURL: environment.firebase.databaseURL,
            projectId: environment.firebase.projectId,
            storageBucket: environment.firebase.storageBucket,
            messagingSenderId: environment.firebase.messagingSenderId,
            appId: environment.firebase.appId
        };
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);*/

        if (environment.production) {
            window.console.log = function () {};   // disable any console.log debugging statements in production mode
            window.console.error = function () {};
            window.console.warn = function () {};
            window.console.info = function () {};
            window.console.debug = function () {};
        }

        sessionStorage.clear();

        this._navigationService.get();
        // Get default navigation
        this._navigationService.navigation$.pipe(takeUntil(this._unsubscribeAll))
        .subscribe((navigation) => {

            this.navigation = navigation;
        });



    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        /*this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if ( this.fuseConfig.layout.width === 'boxed' )
                {
                    this.document.body.classList.add('boxed');
                }
                else
                {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for ( let i = 0; i < this.document.body.classList.length; i++ )
                {
                    const className = this.document.body.classList[i];

                    if ( className.startsWith('theme-') )
                    {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
            /*let navItem = this._fuseNavigationService.getNavigation('Admin');
            if(navItem[0]!=undefined){
                navItem[0].hidden(            }
            this._fuseNavigationService.storeNavigation('Admin').
        /*this._fuseNavigationService.updateNavigationItem('Admin', {
            hidden: true
        });*/
        this._databaseService.initDatabase();
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        this._databaseService.disconnectUser();

        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }



    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
       // this._fuseSidebarService.getSidebar(key).toggleOpen();
    }


}
