import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Point")
export class Point {

    @JsonProperty("X", Number, true)
    private X: number;
    @JsonProperty("Y", Number, true)
    private Y: number;

    constructor(x: number = 0, y: number = 0) {
        this.X = x;
        this.Y = y;
    }

    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
	// -----------------------------------------------------------------------------------------------------
    get x(): number { return this.X; }
    set x(value: number) { this.X = value; }

    get y(): number { return this.Y; }
    set y(value: number) { this.Y = value; }
}