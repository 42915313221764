import { JsonObject, JsonProperty } from 'json2typescript';

@JsonObject('KeyValue')
export class KeyValue {
    @JsonProperty('key', String, true)
    public key : string = '';
    @JsonProperty('value', String, true)
    public value : string = '';
    @JsonProperty('volume', Number, true)
    public volume : number = 100;
}


/**
 * HTML options
 */
@JsonObject('HTMLOptions')
export class HTMLOptions {

    @JsonProperty('Logo', Boolean, true)
    private Logo: boolean = false;

    @JsonProperty('Contact', Boolean, true)
    private Contact: boolean = false;

    @JsonProperty('Plan', Boolean, true)
    private Plan: boolean = false;

    @JsonProperty('Floor', Boolean, true)
    private Floor: boolean = false;

    @JsonProperty('Nadir', Boolean, true)
    private Nadir: boolean = false;

    @JsonProperty('Description', Boolean, true)
    private Description: boolean = false;

    @JsonProperty('SceneList', Boolean, true)
    private SceneList: boolean = false;

    @JsonProperty('SceneToHide', [String], true)
    private SceneToHide: string[] = [];

    @JsonProperty('ShowMapOnStart', Boolean, true)
    private ShowMapOnStart: boolean = false;

    @JsonProperty('MapAlwaysAvailable', Boolean, true)
    private MapAlwaysAvailable: boolean = false;

    @JsonProperty('TemplateType', Number, true)
    private TemplateType: number = 0;

    @JsonProperty('ContactEmail', String, true)
    private ContactEmail: string = '';

    @JsonProperty('ContactPhone', String, true)
    private ContactPhone: string = '';

    @JsonProperty('LogoKey', String, true)
    private LogoKey: string = '';

    @JsonProperty('FloorKey', String, true)
    private FloorKey: string = '';


    @JsonProperty('DescriptionKey', String, true)
    private DescriptionKey: string = '';

    @JsonProperty('NadirKey', String, true)
    private NadirKey: string='';

    @JsonProperty('NadirSize', Number, true)
    private NadirSize: number=1;

    @JsonProperty('LogoLink', String, true)
    private LogoLink: string='';

    @JsonProperty('NadirLink', String, true)
    private NadirLink: string='';

    @JsonProperty('ListAudio', [KeyValue], true)
    private ListAudio: KeyValue[] = [];

    @JsonProperty('EventAudio', Boolean, true)
    private EventAudio: boolean = false;
 
    private DescriptionUrl: string = '';
    private FloorUrl: string = '';
    private LogoUrl: string = '';
    private NadirUrl: string = '';
    

    constructor() {}

    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
    // -----------------------------------------------------------------------------------------------------

    get logo(): boolean { return this.Logo; }
    set logo(value: boolean) { this.Logo = value; }

    get contact(): boolean { return this.Contact; }
    set contact(value: boolean) { this.Contact = value; }

    get plan(): boolean { return this.Plan; }
    set plan(value: boolean) { this.Plan = value; }

    get floor(): boolean { return this.Floor; }
    set floor(value: boolean) { this.Floor = value; }

    get nadir(): boolean { return this.Nadir; }
    set nadir(value: boolean) { this.Nadir = value; }

    get description(): boolean { return this.Description; }
    set description(value: boolean) { this.Description = value; }

    get sceneList(): boolean { return this.SceneList; }
    set sceneList(value: boolean) { this.SceneList = value; }

    get sceneToHide(): string[] { return this.SceneToHide; }
    set sceneToHide(value: string[]) { this.SceneToHide = value; }

    get showMapOnStart(): boolean { return this.ShowMapOnStart; }
    set showMapOnStart(value: boolean) { this.ShowMapOnStart = value; }

    get mapAlwaysAvailable(): boolean { return this.MapAlwaysAvailable; }
    set mapAlwaysAvailable(value: boolean) { this.MapAlwaysAvailable = value; }

    get templateType(): number { return this.TemplateType; }
    set templateType(value: number) { this.TemplateType = value; }

    get contactEmail(): string { return this.ContactEmail; }
    set contactEmail(value: string) { this.ContactEmail = value; }

    get contactPhone(): string { return this.ContactPhone; }
    set contactPhone(value: string) { this.ContactPhone = value; }

    get logoUrl(): string { return this.LogoUrl; }
    set logoUrl(value: string) { this.LogoUrl = value; }

    get nadirUrl(): string { return this.NadirUrl; }
    set nadirUrl(value: string) { this.NadirUrl = value; }

    get logoKey(): string { return this.LogoKey; }
    set logoKey(value: string) { this.LogoKey = value; }

    get floorUrl(): string { return this.FloorUrl; }
    set floorUrl(value: string) { this.FloorUrl = value; }

    get floorKey(): string { return this.FloorKey; }
    set floorKey(value: string) { this.FloorKey = value; }

    get nadirKey(): string { return this.NadirKey; }
    set nadirKey(value: string) { this.NadirKey = value; }

    get nadirSize(): number { return this.NadirSize; }
    set nadirSize(value: number) { this.NadirSize = value; }

    get descriptionUrl(): string { return this.DescriptionUrl; }
    set descriptionUrl(value: string) { this.DescriptionUrl = value; }

    get descriptionKey(): string { return this.DescriptionKey; }
    set descriptionKey(value: string) { this.DescriptionKey = value; }

    get logoLink(): string { return this.LogoLink; }
    set logoLink(value: string) { this.LogoLink = value; }

    get nadirLink(): string { return this.NadirLink; }
    set nadirLink(value: string) { this.NadirLink = value; }

    get eventAudio(): boolean { return this.EventAudio; }
    set eventAudio(value: boolean) { this.EventAudio = value; }

    get listAudio(): KeyValue[] { return this.ListAudio; }
    set listAudio(value: KeyValue[]) { this.ListAudio = value; }

    setDefaults()
    {
        this.Logo = true;
        this.contact = true;
        this.nadir = true;
        this.logoLink="https://explorations360.com/";
        this.nadirLink="https://explorations360.com/";
        this.contactEmail = "easyshare@explorations360.com";
    }

    setDefaultLogo()
    {
        this.Logo = true;
        this.logoLink="https://explorations360.com/";
    }

    setDefaultNadir()
    {
        this.nadir = true;
        this.nadirLink="https://explorations360.com/";
    }

}