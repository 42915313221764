import { JsonObject, JsonProperty, Any } from "json2typescript";


@JsonObject("Card")
export class Card {

    @JsonProperty("Key", String, true)
    Key: string = "";
    @JsonProperty("Title", String, true)
    Title: string = "";
    @JsonProperty("Description", String, true)
    Description: string = "";
    @JsonProperty("Type", String, true)
    Type: string = "";
	@JsonProperty("LastUpdate", String, true)
	LastUpdate: string = Date.now().toString();
    @JsonProperty("Preview", String, true)
    Preview: string = "";
    PreviewUrl: string = "";
    @JsonProperty("Tags", [String], true)
    Tags: string[] = []; 


    constructor() {}
    
    get title(): string { return this.Title; }
    set title(value: string) { this.Title = value; }

    get description(): string { return this.Description; }
    set description(value: string) { this.Description = value; }


    get key(): string { return this.Key; }
    set key(value: string) { this.Key = value; }
    
    get type() { return this.Type; }
    get tags() { return this.Tags; }
  
    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
    public getKey()         : string { return this.Key; }
    public getTitle()       : string { return this.Title; }
    public getDescription() : string { return this.Description; }
    public getType()        : string { return this.Type; }
    public getlastUpdate()  : string { return this.LastUpdate; }
    public getPreview()     : string { return this.Preview; }
    public getPreviewUrl()  : string { return this.PreviewUrl+'?v='+this.LastUpdate; }
    public getTags()    : string[] { return this.Tags; }
    

    // -----------------------------------------------------------------------------------------------------
    // @ SETTER
    // -----------------------------------------------------------------------------------------------------
    public setKey(key: string)                  { this.Key = key; }
    public setTitle(title: string)              { this.Title = title; }
    public setDescription(description: string)  { this.Description = description; }
    public setType(type: string)                { this.Type = type; }
    public setLastUpdate(lastUpdate: string)    { this.LastUpdate = lastUpdate; }
    public setPreview(preview: string)          { this.Preview = preview; }
    public setPreviewUrl(previewUrl: string)    { this.PreviewUrl = previewUrl; }
    public setTags(tags: string[])        { this.Tags = tags; }
    
	// -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    public removeTag(name :string){
        let index = this.Tags.indexOf(name);
        if(index>=0){
            this.Tags.splice(index,1);
        }
    }

}