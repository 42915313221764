import { FuseConfigService } from '@fuse/services/config';
import { DatabaseService } from 'app/services/database.service';
import { AuthService } from 'app/services/auth.service';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BooleanInput } from '@angular/cdk/coercion';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { User } from 'app/models/user.model';
import { UserService } from 'app/services/user.service';
import { Scheme } from 'app/core/config/app.config';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { TranslocoModule } from '@ngneat/transloco';

@Component({
    selector: 'user',
    templateUrl: './user.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
    exportAs: 'user',
    standalone: true,
    imports: [
        TranslocoModule,
        MatButtonModule,
        MatMenuModule,
        MatIconModule,
        NgClass,
        MatDividerModule,
    ],
})
export class UserComponent implements OnInit, OnDestroy
{
    /* eslint-disable @typescript-eslint/naming-convention */
    static ngAcceptInputType_showAvatar: BooleanInput;
    /* eslint-enable @typescript-eslint/naming-convention */

    @Input() showAvatar: boolean = true;
    user: User;

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    /**
     * Constructor
     */
    constructor(
        private _changeDetectorRef: ChangeDetectorRef,
        private _router: Router,
        private _authService: AuthService,
        private _databaseService: DatabaseService,
        private _fuseConfigService: FuseConfigService
    )
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to user changes
        this._databaseService.userSubject.subscribe(
            (user: User) => {
            //    console.log("In user components");
          //      console.log(user);
                this.user = user;

                // Mark for check
                this._changeDetectorRef.markForCheck();
            });

        let scheme : Scheme = 'light';
        let sc =localStorage.getItem('scheme');
        if(sc !== null){
            scheme = sc as Scheme;
        }
        this.setScheme(scheme);
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(undefined);
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Set the scheme on the config
     *
     * @param scheme
     */
     setScheme(scheme: Scheme): void
     {
         this._fuseConfigService.config = {scheme};
         localStorage.setItem('scheme', scheme);
     }

    /**
     * Sign out
     */
    signOut(): void
    {
        this._authService.signOutUser();
        this._databaseService.disconnectUser();
        this._databaseService.logout();
        //this._router.navigate(['/sign-out']);
    }

    myaccount(): void
    {
        this._router.navigate(['/pages/profile'])
    }
}