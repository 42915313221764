import { JsonObject, JsonProperty } from "json2typescript";
import { Environment } from "./environment.model";
import { Interface } from './interface.model';
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable } from 'rxjs';
import { DatabaseService } from 'app/services/database.service';
import { CollectionViewer } from '@angular/cdk/collections';
import { User, UserType, UserProject } from './user.model';

@JsonObject("Device")
export class Device {

	@JsonProperty("Key", String, true)
	Key: string = "";
	@JsonProperty("Name", String, true)
	Name: string = "";
    @JsonProperty("Description", String, true)
	Description: string = "";
	@JsonProperty("User", String, true)
	User: string = "";
	@JsonProperty("Projects", [String], true)
	Projects: string[] = [];
    @JsonProperty("Diffusion", String, true)
	Diffusion: string = undefined;
	@JsonProperty("LastUpdate", String, true)
	LastUpdate: string = Date.now().toString();
	@JsonProperty("VideoWait", String, true)
	VideoWait: string = "";
	@JsonProperty("IsBorne", Boolean, true)
	IsBorne: boolean = false;

	constructor() {}

	// -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
	getKey() 			: string { return this.Key; }
	getProjects() 		: string[] { return this.Projects; }
	getDiffusion() 		: string { return this.Diffusion; }
	getName() 			: string { return this.Name; }
    getDescription() 	: string { return this.Description; }
	getUser() 			: string { return this.User; }
	getLastUpdate() 	: string { return this.LastUpdate; }
	getVideoWait() 		: string { return this.VideoWait; }
	getIsBorne() 		: boolean { return this.IsBorne; }

	// -----------------------------------------------------------------------------------------------------
	// @ SETTER
	// -----------------------------------------------------------------------------------------------------
	setKey(key: string) 							{ this.Key = key; }
	setProjects(Projects:  string[])				{ this.Projects = Projects; }
	setDiffusion(Diffusion:  string)				{ this.Diffusion = Diffusion; }
	setName(Name: string) 							{ this.Name = Name; }
	setDescription(Description:  string)			{ this.Description = Description; }
	setUser(User: string) 							{ this.User = User; }
	setLastUpdate(lastUpdate: string) 				{ this.LastUpdate = lastUpdate; }
	setVideoWait(VideoWait: string) 				{ this.VideoWait = VideoWait; }
	setIsBorne(IsBorne: boolean) 					{ this.IsBorne = IsBorne; }

	// -----------------------------------------------------------------------------------------------------
	// @ PUBLIC METHODS
	// -----------------------------------------------------------------------------------------------------
	
}
export class DeviceUserTable{
	deviceKey: string = "";
	deviceName: string = "";
	deviceUser: string = "";
	deviceProjects: number;
	deviceLastUpdate: string = Date.now().toString();
	userKey: string = "";
	userStatus: number = -1;
    userEmail: string = "";
    userUsername: string = "";
    userName: string = "";
	userVersion: number = 1;
	userDevicePassword: string = "";
	userStartDate: Date = new Date();
	userEndDate: Date = new Date("2035-1-1");
	userType: UserType = UserType.User;

	Device : Device;
	User : User;
	constructor() {
		this.Device= new Device();
		this.User= new User();
		this.User.setType(UserType.Device);
	}

	// -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
	getDevice() 	: Device { return this.Device; }
	getUser() 		: User { return this.User; }
	
	// -----------------------------------------------------------------------------------------------------
	// @ SETTER
	// -----------------------------------------------------------------------------------------------------
	setDevice(Device: Device) 	{ 
		if(Device==null)
			return;
		this.Device = Device;
		this.deviceKey= Device.getKey();
		this.deviceName= Device.getName();
		this.deviceUser= Device.getUser();
		this.deviceProjects = Device.getProjects().length;
		this.deviceLastUpdate= Device.getLastUpdate();
	}
	setUser(User: User) 		{ 
		this.User = User; 
		this.userKey = User.getKey(); 
		this.userStatus = User.getStatus(); 
		this.userEmail = User.getEmail(); 
		this.userUsername = User.getUsername(); 
		this.userName = User.getFirstName()+" "+User.getLastName(); 
		this.userVersion = User.getVersion(); 
		this.userDevicePassword = User.getDevicePassword(); 
		this.userStartDate = User.getStartDate(); 
		this.userEndDate = User.getEndDate(); 
		this.userType = User.getType(); 
	}
}
export class DeviceUserDataSource implements DataSource<DeviceUserTable> {

    private devicesSubject = new BehaviorSubject<DeviceUserTable[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);
    public loading = this.loadingSubject.asObservable();

    constructor(private _databaseService: DatabaseService) {}

    connect(collectionViewer: CollectionViewer): Observable<DeviceUserTable[]> {
        return this.devicesSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.devicesSubject.complete();
        this.loadingSubject.complete();
    }

    loadDevices() {

        this.loadingSubject.next(true);

        this._databaseService.findDeviceUserList().pipe().subscribe(devices => {this.devicesSubject.next(devices);this.loadingSubject.next(false)});
	}

}   
export class UserDataSource implements DataSource<DeviceUserTable> {

    private usersSubject = new BehaviorSubject<DeviceUserTable[]>([]);
    private loadingSubject = new BehaviorSubject<boolean>(false);

    public loading = this.loadingSubject.asObservable();

    constructor(private _databaseService: DatabaseService) {}

    connect(collectionViewer: CollectionViewer): Observable<DeviceUserTable[]> {
        return this.usersSubject.asObservable();
    }

    disconnect(collectionViewer: CollectionViewer): void {
        this.usersSubject.complete();
        this.loadingSubject.complete();
    }

	loadUsers() {

        this.loadingSubject.next(true);

        this._databaseService.findUserList().pipe().subscribe(devices => {this.usersSubject.next(devices);});
	}
}    

