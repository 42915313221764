import { Injectable } from '@angular/core';
import { SessionStat, Statistics, StoryStat } from 'app/models/statistics.model';
import { User } from 'app/models/user.model';
import { Database, get, ref, remove, set } from 'firebase/database';
import { JsonConvert } from 'json2typescript';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StatisticsService {
		
    database : Database;

    jsonConvert : JsonConvert;

    statistics : Statistics;
    statisticsSubject: Subject<Statistics> = new Subject<Statistics>();
    user : User;

	constructor() { 
        this.jsonConvert = new JsonConvert();
    }

	init(db : Database)
    {
        this.database = db;
    }

    setUser(user: User)
    {
        this.user = user;
    }

    async getStatistics()
    {
        const allPromises : Promise<any>[] = [];

        this.statistics = new Statistics();
        this.statistics.stories = [];

        for(const key of this.user.Versions[this.user.Version].Scenarios)
        {
            allPromises.push(get(ref(this.database,'statistics/stories/' + key))
            .then((snapshot) => {
                if (!snapshot.exists()) {
                    return;
                }
                const storyStat = new StoryStat();
                storyStat.key = key;
                storyStat.sessions = [];
                console.log('#########################################');
                console.log(snapshot.val());
                snapshot.forEach((child) => {
                    child.forEach((snapshotSession) => {
                        storyStat.sessions.push(this.jsonConvert.deserializeObject(snapshotSession.val(),SessionStat));
                    });
                });
                
                const index = this.statistics.stories.push(storyStat);
                this.statistics.stories[index - 1].key = key;
                
                console.log(this.statistics.stories[index - 1]);
            }).catch((error) => {console.error(error);}));
        }

        await Promise.all(allPromises);

        this.emitStatistics(this.statistics);
    }

    emitStatistics(statistics : Statistics)
    {
        //console.log("Emitting statistics");
        this.statistics = statistics;
        this.statisticsSubject.next(this.statistics);
        //console.log(JSON.stringify(this.statistics));
    }

    deleteStatistics(key)
    {
        if(key.length === 0)
            return;
        remove(ref(this.database,'statistics/stories/' + key)).then(() => {
            const index=this.statistics.stories.findIndex((story) => story.key === key);
            if(index !== -1)
                this.statistics.stories.splice(index,1);
            this.statisticsSubject.next(this.statistics);})
        .catch((error) => {
            console.error(error);
        })
    }

   
}
