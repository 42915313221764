import { Any, JsonObject, JsonProperty } from "json2typescript";

export enum ConditionType
{
    'Condition','OpenEvent','CloseEvent','Question','QuestionStatus','Questionnaire','Formateur','PlayOn','Variable','SceneOpen'
}
export enum ConditionOpertor
{
    'And','Or' 
}

@JsonObject("Condition")
export class Condition {

    @JsonProperty("key", String, true)
    key: string;
    
    @JsonProperty("type", Number, true)
    type: number;

    @JsonProperty("params", Any, true)
    params: any;
    
    constructor(key="",type=0,params=undefined) {
        this.key = key;
        this.type = type;
        this.params = params;
    }

    
    
    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
	// -----------------------------------------------------------------------------------------------------

}

@JsonObject("Conditions")
export class Conditions {

    @JsonProperty("conditions", [Condition], true)
    conditions : Condition[] = [];
    
    @JsonProperty("op", Number, true)
    op: number;

  
    
    constructor(conditions=[],op=0) {
        this.op = op;
        this.conditions = conditions;
    }
    
    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
	// -----------------------------------------------------------------------------------------------------

}