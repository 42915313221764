import { FirebaseStorage, getDownloadURL, ref } from 'firebase/storage';
import { Injectable } from '@angular/core';

import { Environment } from 'app/models/environment.model';


import { runInThisContext } from 'vm';
import { Interface } from 'app/models/interface.model';
import { Database } from 'firebase/database';
import { DatabaseService } from './database.service';

@Injectable({
  	providedIn: 'root'
})
export class InterfaceService {
	
    Interface: Interface = new Interface();

    constructor() {}

    get interface() { return this.Interface; }

    storage : FirebaseStorage;
    databaseService : DatabaseService;
    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
    getInterface() : Interface {
        return this.Interface;
    }


    // -----------------------------------------------------------------------------------------------------
    // @ SETTER
    // -----------------------------------------------------------------------------------------------------
    init(storage,database)
    {
        this.storage = storage;
        this.databaseService = database;
    }

    setInterface(interf: Interface) {
        if(interf == undefined || interf.getKey() == "")
            return;

        this.Interface = interf;
        //GET DOWNLOAD URL !
        const interfaceKey = interf.Key;
        let filename ="";
        //Interface
        const indexSelected = parseInt(this.Interface.SelectedImage)
        if(this.Interface.ImageUrl=="" && this.Interface.Image !== "")
        {
            filename = this.Interface.Image;
            filename = 'image.' + filename.substring(filename.lastIndexOf('.') + 1);

            console.log(this.Interface.Image);
            console.log('Interface/' + interfaceKey + '/Image/' + filename);

            getDownloadURL(ref(this.storage,'Interface/' + interfaceKey + 
            '/Image/' + filename)).then((url)=>{
                
                this.Interface.ImageUrl=url;
                if(this.Interface.Image==this.Interface.SelectedImage)
                {
                    //console.log(url);
                    this.Interface.setSelectedImageUrl(url);
                }
            }).catch((error)=>{ 
                console.log(error);
            });
        }
        if(this.Interface.getSelectedImageUrl() == "" && (indexSelected>=0 || indexSelected < Interface.defaultImagesUrl.length))
        {
            this.Interface.setSelectedImageUrl(Interface.defaultImagesUrl[indexSelected]);
        }
        else if(this.Interface.getSelectedImageUrl() == "" && this.Interface.Image != "")
        {
            this.Interface.setSelectedImageUrl(this.Interface.ImageUrl);
        }else if(this.Interface.getSelectedImageUrl() != "" && this.Interface.getSelectedImage().length>2){
            this.Interface.setSelectedImageUrl(this.Interface.ImageUrl);
        }

        if(this.Interface.Logo !== "" && this.Interface.Logo !== Interface.defaultLogo && this.Interface.LogoUrl !== "assets/images/logos/logo_2couleurs.svg")
        {
            filename = this.Interface.Logo;
            //console.log(filename);
            filename = 'logo.' + filename.substring(filename.lastIndexOf('.') + 1);
            //console.log(filename);
            console.log('Interface/' + interfaceKey + '/Logo/' +filename);
            getDownloadURL(ref(this.storage,'Interface/' + interfaceKey + 
            '/Logo/' +filename)).then((url)=>{
                //console.log(url);
                this.Interface.LogoUrl=url;
            })
        }else {
            this.Interface.LogoUrl = Interface.defaultLogo;
        }
        
       
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    createNewInterface() {

        // Project
        const newInterface = new Interface();
        newInterface.setKey(this.databaseService.generateKey());
        
        this.Interface = newInterface;
    }

    

}
