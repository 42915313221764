import { JsonObject, JsonProperty } from "json2typescript";
import { Content } from './content.model';

@JsonObject("Tag")
export class Tag {

	@JsonProperty("Name", String, true)
	Name : string = "";
	@JsonProperty("Color", String, true)
	Color : string = "#d50000";
	@JsonProperty("Icon", String, true)
	Icon : string = "label";

    private _animationState = "highlightDown";
    private _edit = false;

	constructor(name?: string, color?: string) {
		this.Name = name;
		this.Color = (color!=undefined && color !=null && color!="")?color:"#d50000";
	}

	get icon() { return this.Icon; }
	get color() { return this.Color; }
	get name() { return this.Name; }
    
    get animationState(): string { return this._animationState; }
    set animationState(value: string) { this._animationState = value; }

    get edit(): boolean { return this._edit; }
    set edit(value: boolean) { this._edit = value; }

	// -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
	getName() 		: string { return this.Name; }
	getColor() 		: string { return this.Color; }
	getIcon() 		: string { return this.Icon; }

	// -----------------------------------------------------------------------------------------------------
	// @ SETTER
	// -----------------------------------------------------------------------------------------------------
	setName(Name: string) 		{ this.Name = Name; }
	setColor(Color:  string)	{ this.Color = Color; }
	setIcon(Icon: string) 		{ this.Icon = Icon; }

	// -----------------------------------------------------------------------------------------------------
	// @ PUBLIC METHODS
	// -----------------------------------------------------------------------------------------------------
	getTagClass(){
		switch(this.Color.toLowerCase()){
			case"#d50000":
			return "redColor";
			case"#ff1744":
			return "pinkColor";
			case"#d500f9":
			return "purpleColor";
			case"#3d5afe":
			return "indigoColor";
			case"#00e5ff":
			return "cyanColor";
			case"#00c853":
			return "greenColor";
			case"#ffea00":
			return "yellowColor";
			case"#ff6d00":
			return "orangeColor";
			case"#6d4c41":
			return "brownColor";
			case"#757575":
			return "greyColor";
			case"#000000":
			return "blackColor";
		}
	}
}

