<!-- Button -->
<div *transloco="let t"> 
<button
    mat-icon-button
    [matMenuTriggerFor]="userActions">
    <span class="relative">
        <mat-icon>account_circle</mat-icon>
    </span>
</button>

<mat-menu
    [xPosition]="'before'"
    #userActions="matMenu">
    <button
        mat-menu-item
        (click)="myaccount()">
        <mat-icon>account_circle</mat-icon>
        <span>Mon compte</span>
    </button>

    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        [matMenuTriggerFor]="selectTheme">
        <mat-icon>palette</mat-icon>
        <span>{{t("theme")}}</span>
    </button>    
    <mat-divider class="my-2"></mat-divider>
    <button
        mat-menu-item
        (click)="signOut()">
        <mat-icon>logout</mat-icon>
        <span>{{t("deconnexion")}}</span>
    </button>
</mat-menu>

<mat-menu
    class="theme-select"
    #selectTheme="matMenu">
    <button
        mat-menu-item
        (click)="setScheme('dark')">
        <mat-icon>dark_mode</mat-icon>
        <span>{{t("themesombre")}}</span>
    </button>
    <button
        mat-menu-item
        (click)="setScheme('light')">
        <mat-icon>light_mode</mat-icon>
        <span>{{t("themeclair")}}</span>
    </button>
    <button
        mat-menu-item
        (click)="setScheme('auto')">
        <mat-icon>auto_awesome</mat-icon>
        <span>{{t("auto")}}</span>
    </button>
</mat-menu>

</div>
