import { DatabaseService } from 'app/services/database.service';
import { Injectable } from '@angular/core';

import { Project } from 'app/models/project.model';
import { Environment } from 'app/models/environment.model';
import { Interaction } from '../models/interaction.model';
import { Content } from 'app/models/content.model';


import { runInThisContext } from 'vm';
import { Interface } from 'app/models/interface.model';
import { getDownloadURL, ref } from 'firebase/storage';

@Injectable({
  	providedIn: 'root'
})
export class ProjectService {
	
    project: Project = new Project();
    databaseService : DatabaseService;
    constructor() {}

    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
    getProject() : Project {
        return this.project;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ SETTER
    // -----------------------------------------------------------------------------------------------------

    init(databaseService){
        this.databaseService=databaseService;
    }
    setProject(project: Project) {
        this.project = project;
        if(project == undefined || project.Environments[0] == undefined)
            return;
        //GET DOWNLOAD URL !
        const projectKey = project.Key;
        const environmentKey = project.Environments[0].Key;
        const interactionKey = project.Environments[0].Interactions[0].Key;
        let filename ="";
        //Interface
        const indexSelected = parseInt(this.project.Interface.SelectedImage)
        if(this.project.Interface.getSelectedImageUrl() == "" && (indexSelected>=0 || indexSelected < Interface.defaultImagesUrl.length))
        {
            this.project.Interface.setSelectedImageUrl(Interface.defaultImagesUrl[indexSelected]);
        }
        else if(this.project.Interface.ImageUrl == "" && this.project.Interface.Image != "")
        {
            filename = this.project.Interface.Image;
            filename = 'image.' + filename.substring(filename.lastIndexOf('.') + 1);
            getDownloadURL(ref(this.databaseService.storage,'Projects/' + projectKey + 
            '/Interface/Image/' + filename)).then((url)=>{
                
                this.project.Interface.ImageUrl=url;
                if(this.project.Interface.Image==this.project.Interface.SelectedImage)
                {
                    //console.log(url);
                    this.project.Interface.setSelectedImageUrl(url);
                }
            })
        }
        if(this.project.Interface.Logo != "" && this.project.Interface.Logo != Interface.defaultLogo && this.project.Interface.LogoUrl != "assets/images/logos/logo_2couleurs.svg")
        {
            filename = this.project.Interface.Logo;
            //console.log(filename);
            filename = 'logo.' + filename.substring(filename.lastIndexOf('.') + 1);
            //console.log(filename);
            getDownloadURL(ref(this.databaseService.storage,'Projects/' + projectKey + 
            '/Interface/Logo/' +filename)).then((url)=>{
                //console.log(url);
                this.project.Interface.LogoUrl=url;
            })
        }else {
            this.project.Interface.LogoUrl = Interface.defaultLogo;
        }
        //Medias
        project.Environments[0].Interactions[0].Contents.forEach(content => {
            if(content.DataUrl == "" && content.Data != "" && content.Status>0){
                filename = content.Data;
                filename = 'data.' + filename.substring(filename.lastIndexOf('.') + 1);
                getDownloadURL(ref(this.databaseService.storage,'Projects/' + projectKey + 
                '/Environments/' + environmentKey + 
                '/Interactions/' + interactionKey +
                '/Contents/' + content.Key +
                '/Data/' + filename)).then((url)=>{
                    
                    content.DataUrl=url;
                })
            }
            if(content.PreviewUrl == "" && content.Preview != ""){
                filename = content.Preview;
                filename = 'preview.' + filename.substring(filename.lastIndexOf('.') + 1);
                getDownloadURL(ref(this.databaseService.storage,'Projects/' + projectKey + 
                '/Environments/' + environmentKey + 
                '/Interactions/' + interactionKey +
                '/Contents/' + content.Key +
                '/Preview/' + filename)).then((url)=>{
                    
                    content.PreviewUrl=url;
                })
            }
        });
       
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    createNewProject() {

        // Project
        const newProject = new Project();
        newProject.setKey(this.databaseService.generateKey());

        // Environment
        const environment = new Environment();
        environment.setKey(this.databaseService.generateKey());
        newProject.addEnvironment(environment);

        // Interaction
        const interaction = new Interaction();
        interaction.setKey(this.databaseService.generateKey());
        newProject.getEnvironments()[0].addInteraction(interaction);

        //Interface
        newProject.setInterface(new Interface());
        
        this.project = newProject;
    }

    // === CONTENTS === //
    // This is the path with default environment and interaction
    getContents() : Content[] {
        if (this.project != undefined && this.project.getEnvironments()!= undefined && this.project.getEnvironments().length > 0 && this.project.getEnvironments()[0].getInteractions() != undefined && this.project.getEnvironments()[0].getInteractions().length > 0) {
            return this.project.getEnvironments()[0].getInteractions()[0].getContents();
        }
        return [];
    }

}