import { Injectable } from '@angular/core';

import { Device } from 'app/models/device.model';

import { DatabaseService } from './database.service';

@Injectable({
  	providedIn: 'root'
})
export class DevicesService {
	
	
    devices: Device[] = [];
    
    constructor(
       // private _databaseService : DatabaseService
    ) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
    getDevices() : Device[] {
        return this.devices;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ SETTER
    // -----------------------------------------------------------------------------------------------------
    setDevices(devices: Device[]) {
        //console.log("SET DEVICES !!!");
        if(devices == undefined)
        return;
        /*devices.forEach(device => {
            console.log(device);
        });*/
        this.devices = devices;
       
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

  /*  createNewDevice() {

        // Library
        var newDevices = new Device();
        newDevices.setKey(this._databaseService.generateKey());
        
        this.devices.push(newDevices);
        return newDevices.getKey();
    }*/
    deleteDevice(deviceKey : string){
        for (let i = 0; i < this.devices.length; i++) {
            if(this.devices[i]!=null && this.devices[i].getKey()==deviceKey){
                //console.log("DELETE DEVICE !!!!!");
                //console.log(this.devices);
                this.devices.splice(i,1);
                //console.log(this.devices);
                return;
            }
            
        }
    }
    changeDevice(device : Device){
        let deviceKey = device.getKey();
        for (let i = 0; i < this.devices.length; i++) {
            if(this.devices[i].getKey()==deviceKey){
                this.devices[i]=device;
                return;
            }
            
        }
    }
    addDevice(device : Device){
        if(device!=null)
        this.devices.push(device);
    }


    
   

}