import { JsonObject, JsonProperty } from 'json2typescript';

/**
 * HTML options
 */
@JsonObject('StoryOptions')
export class StoryOptions {

    @JsonProperty('CursorModel', Number, true)
    private CursorModel: number = 0;

    constructor() {}

    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
    // -----------------------------------------------------------------------------------------------------

    get cursorModel(): number { return this.CursorModel; }
    set cursorModel(value: number) { this.CursorModel = value; }



}