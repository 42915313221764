import { JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Diffusion } from './diffusion.model';

@JsonConverter
class DateConverter implements JsonCustomConvert<Date> {

	// Object -> Json
	serialize(date: Date): string {
		//correction pour pb sur Safari MAC OS
		let month :string="";
		month = (date.getMonth()+1).toString();
		month = (month.length<2) ? "0"+month : month;
		let day :string="";
		day = (date.getDate()).toString();
		day = (day.length<2) ? "0"+day : day;
		
		return date.getFullYear() + "-" + month + "-" +  day;	
	}

	// Json -> Object
	deserialize(date: string): Date {
		//correction pour pb sur Safari MAC OS
		const dt=date.split("-");
		return new Date(parseInt(dt[0]),parseInt(dt[1])-1,parseInt(dt[2]));
	}

}

@JsonConverter
class DateHourConverter implements JsonCustomConvert<Date> {

	// Object -> Json
	serialize(date: Date): number {
		return date.getTime();
	}

	// Json -> Object
	deserialize(date: number): Date {
		return new Date(date);
	}

}

@JsonObject("Attempt")
export class Attempt {

    @JsonProperty("StartDate", DateConverter, true)
    private StartDate: Date = new Date(Date.now());
    @JsonProperty("EndDate", DateConverter, true)
    private EndDate: Date = undefined;
    @JsonProperty("Score", Number, true)
    private Score: number = 0;
    @JsonProperty("MaxScore", Number, true)
    private MaxScore: number = 0;
    @JsonProperty("TotalTime", Number, true)
    private TotalTime: number = 0;
    @JsonProperty("SceneTime", Number, true)
    private SceneTime: number = 0;
    @JsonProperty("CurrentScene", String, true)
    private CurrentScene: string = undefined;
    @JsonProperty("CurrentEvent", String, true)
    private CurrentEvent: string = undefined;
    @JsonProperty("IsCompleted", Boolean, true)
    private IsCompleted: boolean = false;

    
    constructor() {}
    
    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
	// -----------------------------------------------------------------------------------------------------

    
    get startDate() : Date { return this.StartDate; }
    set startDate(value: Date) { this.StartDate = value; }

    get endDate() : Date { return this.EndDate; }
    set endDate(value: Date) { this.EndDate = value; }

    get score() : number { return this.Score; }
    set score(value: number) { this.Score = value; }

    get maxScore() : number { return this.MaxScore; }
    set maxScore(value: number) { this.MaxScore = value; }

    get totalTime() : number { return this.TotalTime; }
    set totalTime(value: number) { this.TotalTime = value; }

    get sceneTime() : number { return this.SceneTime; }
    set sceneTime(value: number) { this.SceneTime = value; }

    get currentScene() : string { return this.CurrentScene; }
    set currentScene(value: string) { this.CurrentScene = value; }
    
    get currentEvent() : string { return this.CurrentEvent; }
    set currentEvent(value: string) { this.CurrentEvent = value; }

    get isCompleted() : boolean { return this.IsCompleted; }
    set isCompleted(value: boolean) { this.IsCompleted = value; }
    
    
    // -----------------------------------------------------------------------------------------------------
	// @ Public methods
    // -----------------------------------------------------------------------------------------------------

}

