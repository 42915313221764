import { JsonObject, JsonProperty } from "json2typescript";

import { Scene } from './scene.model';
import { Card } from '../card.model';
import { EventType } from './event.model';
import { HTMLOptions } from './htmlOptions.model';
import { StoryOptions } from "./storyOptions.model";

@JsonObject("QuestionsSet")
export class QuestionsSet {

    @JsonProperty("Key", String, true)
    private Key: string = "";
    @JsonProperty("Clues", [String], true)
    private Clues: String[] = [];
    @JsonProperty("Name", String, true)
    private Name: string = "";
    

    constructor() {
        
      
    }
    
    
    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
	// -----------------------------------------------------------------------------------------------------
    
    
    get clues(): String[] { return this.Clues; }
    set clues(value: String[]) { this.Clues = value; }

    get key(): string { return this.Key; }
    set key(value: string) { this.Key = value; }


    get name(): string { return this.Name; }
    set name(value: string) { this.Name = value; }

   


    // -----------------------------------------------------------------------------------------------------
	// @ Public methods
    // -----------------------------------------------------------------------------------------------------
    

}