import { JsonObject, JsonProperty } from "json2typescript";
import { Point } from "./point.model";

@JsonObject("Vector3")
export class Vector3 extends Point {

    @JsonProperty("Z", Number, true)
    private Z: number;

    constructor(x = 0, y = 0, z = 0) {
        super(x, y);
        this.Z = z;
    }

    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
	// -----------------------------------------------------------------------------------------------------
    get z(): number { return this.Z; }
    set z(value: number) { this.Z = value; }
}