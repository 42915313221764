import { Injectable } from '@angular/core';

import { Library } from 'app/models/library.model';
import { Content } from 'app/models/content.model';
import { Subject } from 'rxjs';
import { Tag } from 'app/models/tag.model';
import { DatabaseService } from './database.service';

@Injectable({
  	providedIn: 'root'
})
export class LibraryService {


    library: Library = new Library();
    private _databaseService : DatabaseService;

    constructor(
    ) {

    }

    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
    getLibrary() : Library {
        return this.library;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ SETTER
    // -----------------------------------------------------------------------------------------------------
    setLibrary(library: Library) {
        this.library = library;
        if(library == undefined)
            return;

    }

    // -----------------------------------------------------------------------------------------------------
    // @ Error on content methods
    // -----------------------------------------------------------------------------------------------------

    errorOnContents: Content[] = [];
    errorOnContentsSubject = new Subject<Content[]>();

    private emitErrorOnContents() {
        this.errorOnContentsSubject.next(this.errorOnContents);
    }

    addErrorOnContent(content: Content) {
        if (!this.errorOnContents.includes(content))
            this.errorOnContents.push(content);

        this.emitErrorOnContents();
    }

    removeErrorOnContent(content: Content) {
        this.errorOnContents.splice(this.errorOnContents.indexOf(content), 1);
    }


    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    createNewLibrary(databaseService = undefined) {
        if(databaseService != undefined){
            this._databaseService= databaseService;
        }
        // Library
        const newLibrary = new Library();
        newLibrary.setKey(this._databaseService.generateKey());

        this.library = newLibrary;
    }

    // === CONTENTS === //
    // This is the path with default content and interaction
    getContents() : Content[] {
        if (this.library != undefined) {
            return this.library.getContents();
        }
        return [];
    }

    getTags() : Tag[]
    {
        if(this.library != undefined){
            return this.library.getTags();
        }
        return [];
    }
    getDiffusionTags() : Tag[]
    {
        if(this.library != undefined){
            return this.library.getDiffusionTags();
        }
        return [];
    }

    removeTagFromMedia(name : string){
        this.getContents().forEach(content => {
            content.removeTag(name);
        });
    }


    /**
     * Get content for key
     *
     * @param key
     *
     * @returns Content or undefined
     */
    getContentForKey(key: string) : Content {
        let content: Content;

        for (var i = 0; i < this.library.Contents.length; i++) {
            if (this.library.Contents[i].getKey() == key) {
                content = this.library.Contents[i];
                break;
            }
        }

        return content;

    }

    /**
     * Get contents for keys
     *
     * @param {string[]} keys
     *
     * @returns List of contents corresponding to keys
     */
    getContentsForKeys(keys: string[]) : Content[] {
        return this.library.Contents.filter(content => keys.includes(content.Key));
    }

    getNextContent(content: Content) : Content {
        let index = this.library.Contents.indexOf(content);
        if(index < this.library.Contents.length - 1){
            return this.library.Contents[index + 1];
        }
        else
            return this.library.Contents[0];
    }

    getPrevContent(content: Content) : Content {
        let index = this.library.Contents.indexOf(content);
        if(index > 0){
            return this.library.Contents[index - 1];
        }
        else
             return this.library.Contents[this.library.Contents.length - 1];
    }


}