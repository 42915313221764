import { JsonObject, JsonProperty, Any } from "json2typescript";
import { Card } from './card.model';
import { MarkerData } from "./scenario/markerData.model";
import { VimeoData } from "./vimeodata.model";

/*export enum ContentType {
    Standard        = "Standard",
    360Monoscopic   = "360Monoscopic",
    360Side         = "360Side",
    360TopBottom    = "360TopBottom"
} */

export enum ContentCategory {
    Undefined = "Undefined",
    Image = "Image",
    Video = "Video",
    Audio = "Audio",
    Gps = "Gps",
    Obj3D = "Obj3D",
    PDF= "PDF"
}

@JsonObject("Content")
export class Content extends Card {


    @JsonProperty("Data", String, true)
    Data: string = "";
    DataUrl: string = "";
    @JsonProperty("DataSize", Number, true)
    DataSize: number = 0;
    @JsonProperty("DataLow", String, true)
    DataLow: string = "";
    DataLowUrl: string = "";
    DataMedUrl: string = "";
    DataHighUrl: string = "";
    DataEditorUrl: string = "";
    @JsonProperty("DataLowSize", Number, true)
    DataLowSize: number = 0;
    @JsonProperty("Status", Number, true)
    Status: number = 0; //0 : created | 1 : ready (all files download)
    @JsonProperty("Resize", Boolean, true)
    Resize: boolean = false;
    @JsonProperty("AutoPreview", Boolean, true)
    AutoPreview: boolean = true;
    @JsonProperty("MarkerData", MarkerData, true)
    MarkerData: MarkerData = undefined;
    @JsonProperty("VimeoData", VimeoData, true)
    VimeoData: VimeoData = undefined;
    @JsonProperty("Languages", [String], true)
    Languages: string[] = [];
    @JsonProperty("DirectUpload", Boolean, true)
    DirectUpload: boolean = false;
    @JsonProperty("GenWebp", Boolean, true)
    GenWebp: boolean = false;

    private _duration: number;

    previewFile: any;
    dataFile: any;
    dataLowFile: any;

    // Only for audio content
    isAudioPlaying = false;
    audioIcon = "play_arrow";

    @JsonProperty("Category", String, true)
    private Category: ContentCategory = ContentCategory.Undefined;

    constructor() {
        super();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Mutator methods
    // -----------------------------------------------------------------------------------------------------
    get category(): ContentCategory { return this.Category; }
    set category(value: ContentCategory) { this.Category = value; }
    public get duration(): number {
        return this._duration;
    }
    public set duration(value: number) {
        this._duration = value;
    }

    get markerData(): MarkerData { return this.MarkerData; }
    set markerData(value: MarkerData) { this.MarkerData = value; }



    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
    public getDataLow()         : string { return this.DataLow; }
    public getDataLowUrl()      : string { return this.DataLowUrl+'?v='+this.LastUpdate; }
    public getDataMedUrl()      : string { return this.DataMedUrl+'?v='+this.LastUpdate; }
    public getDataHighUrl()     : string { return this.DataHighUrl+'?v='+this.LastUpdate; }
    public getDataEditoUrl()    : string { return this.DataEditorUrl+'?v='+this.LastUpdate; }
    public getDataSize()        : number { return this.DataSize; }
    public getData()            : string { return this.Data; }
    public getDataUrl()         : string { return this.DataUrl+'?v='+this.LastUpdate; }
    public getStatus()          : number { return this.Status; }

    public getPreviewFile()     : File { return this.previewFile; }
    public getDataFile()        : File { return this.dataFile; }
    public getDataLowFile()     : File { return this.dataLowFile; }
    public getResize()          : boolean { return this.Resize; }
    public getWebp()            : boolean { return this.GenWebp; }


    // -----------------------------------------------------------------------------------------------------
    // @ SETTER
    // -----------------------------------------------------------------------------------------------------
    public setData(data: string)                { this.Data = data; }
    public setDataUrl(dataUrl: string)          { this.DataUrl = dataUrl; }
    public setDataSize(dataSize: number)        { this.DataSize = dataSize; }
    public setDataLow(dataLow: string)          { this.DataLow = dataLow; }

    public setDataLowUrl(dataLowUrl: string)    { this.DataLowUrl = dataLowUrl; }
    public setDataMedUrl(dataUrl: string)       { this.DataMedUrl = dataUrl; }
    public setDataHighUrl(dataUrl: string)      { this.DataHighUrl = dataUrl; }
    public setDataEditorUrl(dataUrl: string)    { this.DataEditorUrl = dataUrl; }

    public setDataLowSize(dataLowSize: number)  { this.DataLowSize = dataLowSize; }
    public setStatus(status: number)            { this.Status = status; }

    public setPreviewFile(previewFile: File)  { this.previewFile = previewFile; }
    public setDataFile(dataFile: File)        { this.dataFile = dataFile; }
    public setDataLowFile(dataLowFile: File)  { this.dataLowFile = dataLowFile; }
    public setResize(bool : boolean)            { this.Resize = bool; }
    public setWebp(bool : boolean)              { this.GenWebp = bool; }


	// -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
    public getDataUrls(){
        let tmp = [];
        //if(this.dataLowFile != null)
            tmp.push(this.DataLowUrl);
        //if(this.DataMedUrl != null)
            tmp.push(this.DataMedUrl);
        //if(this.DataHighUrl != null)
            tmp.push(this.DataHighUrl);
        //if(this.DataUrl != null)
            tmp.push(this.DataUrl);
        return tmp;
    }

    public urlsReady(){
        if(this.Resize){
            if(this.DataLowUrl == "" || this.DataHighUrl == "" || this.DataMedUrl == ""){

                return false;
            }
        }
        if(this.DataUrl == ""){

            return false;
        }

        return true;
    }
}