import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Interface")
export class Interface {


    @JsonProperty("Key", String, true)
    Key = "";
    
    get key() { return this.Key; }

    @JsonProperty("LastUpdate", String, true)
    LastUpdate = Date.now().toString();


    // V <= 2
    @JsonProperty("Company", String, true)
    Company: string = "";
    @JsonProperty("Message", String, true)
    Message: string = "";
    @JsonProperty("Logo", String, true)
    Logo: string = "";
    //@JsonProperty("LogoUrl", String, true)
    LogoUrl: string = "";
    @JsonProperty("SelectedImage", String, true)
    SelectedImage: string = "";
    //@JsonProperty("SelectedImageUrl", String, true)
    SelectedImageUrl: string = "";
    SelectedPreviewUrl: string = "";
    @JsonProperty("Image", String, true)
    Image: string = "";
    //@JsonProperty("ImageUrl", String, true)
    ImageUrl: string = "";
    @JsonProperty("Audio", String, true)
    audio: string = "";
    //@JsonProperty("AudioUrl", String, true)
    AudioUrlV2: string = "";




    

    // V3

    @JsonProperty("PresentationKey", String, true)
    private PresentationKey = "";
    @JsonProperty("IsBackgroundDefault", Boolean, true)
    private IsBackgroundDefault = true;
    @JsonProperty("BackgroundKey", String, true)
    private BackgroundKey = "0";
    @JsonProperty("IsAudioDefault", Boolean, true)
    private IsAudioDefault = true;
    @JsonProperty("AudioKey", String, true)
    private AudioKey = "0";
    @JsonProperty("DefaultStoryKey", String, true)
    private DefaultStoryKey = "";

    get presentationKey() { return this.PresentationKey; }
    set presentationKey(value: string) { this.PresentationKey = value; }
    
    get isBackgroundDefault() { return this.IsBackgroundDefault; }
    set isBackgroundDefault(value: boolean) { this.IsBackgroundDefault = value; }
    
    get backgroundKey() { return this.BackgroundKey; }
    set backgroundKey(value: string) { this.BackgroundKey = value; }
    
    get isAudioDefault() { return this.IsAudioDefault; }
    set isAudioDefault(value: boolean) { this.IsAudioDefault = value; }
    
    get audioKey() { return this.AudioKey; }
    set audioKey(value: string) { this.AudioKey = value; }

    get defaultStoryKey() { return this.DefaultStoryKey; }
    set defaultStoryKey(value: string) { this.DefaultStoryKey = value; }

    // <= V2
    logoFile: any;
    imageFile: any;
    audioV2File: any;

    // === STATIC === //
    static defaultCompany: string = "Immersion Tools";
    static defaultLogo: string = "logo_2couleurs.svg";
    static defaultLogoUrl: string = "assets/images/logos/logo_2couleurs.svg";
    static defaultImages: string[] = [
        "0",
    ];
    static defaultImagesUrl: string[] = [
        "https://firebasestorage.googleapis.com/v0/b/easybox360-6ca5d.appspot.com/o/Default%2FInterface%2FImage%2F2%2Fdata.jpg?alt=media&token=34654531-e8c5-4317-9e30-54390526913b",
    ];
    static defaultPreviewUrl: string[] = [
        "https://firebasestorage.googleapis.com/v0/b/easybox360-6ca5d.appspot.com/o/Default%2FInterface%2FImage%2F2%2Fpreview.jpg?alt=media&token=cbd6c152-eb3c-4142-87be-5017f3ec0726",
    ];

    constructor() {
        this.Company = Interface.defaultCompany;
        this.Logo = Interface.defaultLogo;
        this.LogoUrl = "";
        this.SelectedImage = Interface.defaultImages[0];
        this.SelectedImageUrl = Interface.defaultImagesUrl[0];
        this.AudioKey="";
    }

    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
    getKey(): string { return this.Key; }
    getLastUpdate(): string { return this.LastUpdate; }
    getCompany(): string { return this.Company; }
    getMessage(): string { return this.Message; }
    getLogo(): string { return this.Logo; }
    getLogoUrl(): string { if (this.Logo == Interface.defaultLogo || this.Logo == "") { return Interface.defaultLogoUrl } return this.LogoUrl; }
    getSelectedImage(): string { return this.SelectedImage; }
    getSelectedImageUrl(): string { return this.SelectedImageUrl; }
    getImage(): string { return this.Image; }
    getImageUrl(): string { return this.ImageUrl; }
    getAudio(): string { return this.audio; }
    getAudioUrl(): string { return this.AudioUrlV2; }

    getLogoFile(): string { return this.logoFile; }
    getImageFile(): string { return this.imageFile; }
    getAudioV2File(): string { return this.audioV2File; }

    // -----------------------------------------------------------------------------------------------------
    // @ SETTER
    // -----------------------------------------------------------------------------------------------------
    //https://firebasestorage.googleapis.com/v0/b/videobroadcast-83d4e.appspot.com/o/Projects%2F-Lz7p4TT4Jk_FJzkiB8T%2FInterface%2FImage%2Fimage.jpg?alt=media&token=2c1f03af-4727-40ed-b8c5-20a6b1ad439e
    setKey(key: string) { this.Key = key; }
    setLastUpdate(lasteUpdate: string) { this.LastUpdate = lasteUpdate; }
    setCompany(company: string) { this.Company = company; }
    setMessage(message: string) { this.Message = message; }
    setLogo(logo: string) { this.Logo = logo; }
    setLogoUrl(logoUrl: string) { this.LogoUrl = logoUrl; }
    setSelectedImage(image: string) { this.SelectedImage = image; }
    setSelectedImageUrl(imageUrl: string) {
        /*if(imageUrl.includes("/o/Projects")){ 
            let i = imageUrl.indexOf('Projects'); 
            let tmp = imageUrl.slice(i); 
            imageUrl = imageUrl.slice(0,i)+tmp.replace('/','%2F');
        } */
        this.SelectedImageUrl = imageUrl;
    }
    setImage(image: string) { this.Image = image; }
    setImageUrl(imageUrl: string) { this.ImageUrl = imageUrl; }
    setAudio(audio: string) { this.audio = audio; }
    setAudioUrl(audioUrl: string) { this.AudioUrlV2 = audioUrl; }

    setLogoFile(logoFile: string) { this.logoFile = logoFile; }
    setImageFile(imageFile: string) { this.imageFile = imageFile; }
    setAudioV2File(audioFile: string) { this.audioV2File = audioFile; }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------
}
