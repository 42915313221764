import { JsonObject, JsonProperty } from "json2typescript";

@JsonObject("Angle")
export class Angle {

    @JsonProperty("Alpha", Number, true)
    private Alpha: number;
    @JsonProperty("Beta", Number, true)
    private Beta: number;
    @JsonProperty("Zeta", Number, true)
    private Zeta: number;

    constructor(alpha: number = 0, beta: number = 0,zeta: number = 100) {
        this.Alpha = alpha;
        this.Beta = beta;
        this.Zeta = zeta;
    }

    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
	// -----------------------------------------------------------------------------------------------------
    get alpha(): number { return this.Alpha; }
    set alpha(value: number) { 

        if (value > 180) {
            this.Alpha = 180;
        } else if (value < -180) {
            this.Alpha = -180;
        } else {
            this.Alpha = value; 
        }
        
    }

    get beta(): number { return this.Beta; }
    set beta(value: number) { 

        if (value > 180) {
            this.Beta = 180;
        } else if (value < -180) {
            this.Beta = -180;
        } else {
            this.Beta = value;
        } 

    }

    get zeta(): number { return this.Zeta; }
    set zeta(value: number) { 

        this.Zeta = value;

    }
}