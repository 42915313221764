import { JsonObject, JsonProperty } from "json2typescript";


@JsonObject('TranslateEvent')
export class TranslateEvent {
    @JsonProperty('Title', String, true)
    Title: string = '';
    @JsonProperty('TitleP', String, true)
    TitleP: string = '';
    @JsonProperty('TextHeader', String, true)
    TextHeader: string = '';
    @JsonProperty('Text', String, true)
    Text: string = '';
    @JsonProperty('Answers', [String], true)
    Answers: string[] = [];
    @JsonProperty('FeedbackGood', String, true)
    FeedbackGood: string = '';
    @JsonProperty('FeedbackBad', String, true)
    FeedbackBad: string = '';
    @JsonProperty('Question', String, true)
    Question: string = '';
    @JsonProperty('Feedback', [String], true)
    Feedback: string[] = [];
    @JsonProperty('Button', String, true)
    Button: string = '';
    @JsonProperty('Description', String, true)
    Description: string = '';
    @JsonProperty('AnswerText', String, true)
    AnswerText: string = '';
    @JsonProperty('Form', [String], true)
    Form: string[] = [];
    @JsonProperty('Key', String, true)
    Key: string = '';
}


@JsonObject('TranslateScene')
export class TranslateScene {
    @JsonProperty('Title', String, true)
    Title: string = '';
    @JsonProperty('Key', String, true)
    Key: string = '';
    @JsonProperty('Events', [TranslateEvent], true)
    Events: TranslateEvent[] = [];
    
}

@JsonObject('TranslateScenario')
export class TranslateScenario {
    @JsonProperty('Title', String, true)
    Title: string = '';
    @JsonProperty('Welcome', String, true)
    Welcome: string = '';

    @JsonProperty('Scenes', [TranslateScene], true)
    Scenes: TranslateScene[] = [];
    
}
