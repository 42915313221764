import { Injectable } from '@angular/core';

import { Subject, Subscription} from 'rxjs';

import { Download,DlType } from 'app/models/download.model';
import { Content } from 'app/models/content.model';

import { FunctionsService } from './functions.service';
import { environment } from 'environments/environment';

import { Observable } from 'rxjs';

//Required to set custom headers and make http requests
import { HttpHeaders, HttpClient, HttpEvent, HttpParams, HttpRequest, HttpEventType } from '@angular/common/http';
import * as tus from 'tus-js-client';

//let Vimeo = require('vimeo').Vimeo;
//let client = new Vimeo(environment.vimeoId, environment.vimeoSecret, environment.vimeo.token);

//import { DatabaseService } from './database.service';

@Injectable({
  providedIn: 'root'
})
export class VimeoService {

    progress: number[] = [];
    subscriptions: Subscription[] = [];
    progressSubject = new Subject<number[]>();

    token = undefined;

    //databaseService: DatabaseService;
    constructor(private http: HttpClient) {

    }
    
   
    directUpload(formData: FormData): Observable<HttpEvent<unknown>> 
    {
        const uploadReq = new HttpRequest('POST', environment.vimeo.url + '/directUpload', formData, {
            reportProgress: true,
        });
        return this.http.request(uploadReq);
    }

    directUploadKey(formData: FormData, key: string = undefined,callback = undefined)
    {
        this.progress[key] = 0;
        const uploadReq = new HttpRequest('POST', environment.vimeo.url + '/directUpload', formData, {
            reportProgress: true,
            //headers: new HttpHeaders({timeout: '1200000'})
        });
        const time = new Date().getTime();
        try{
            this.subscriptions[key] = this.http.request(uploadReq).subscribe((event) => {
                if (event.type === HttpEventType.UploadProgress)
                {
                    this.progress[key] = Math.round(100 * event.loaded / event.total);
                    //console.log(this.progress[key] .toFixed(0) + '%');
                    this.progressSubject.next(this.progress);
                }
                else if (event.type === HttpEventType.Response)
                {
                    this.cancelUpload(key)
                    this.progressSubject.next(this.progress);
                    if(callback !== undefined){
                        callback();
                    }
                }
            });
        }
        catch(e){
            console.log(e);
            console.log('time : ' + (new Date().getTime()-time));
        }
    }

    cancelUpload(key: string)
    {
        this.progress[key]= undefined;
        if(this.subscriptions[key] !== undefined)
        {
            this.subscriptions[key].unsubscribe();
        }
        this.subscriptions[key]= undefined;
    }

    getThumbnails(uri: string): Observable<HttpEvent<unknown>>
    {
        /*if(this.token == undefined)
        {
            await this.getToken();
        }*/

        //console.log('getThumbnails');
        //console.log(this.token);
        let params = new HttpParams();
        params = params.set('uri', uri);
        params = params.set('token', environment.vimeo.token);

        const request = new HttpRequest('GET', environment.vimeo.url + '/getThumbnails', { uri: uri, token: environment.vimeo.token}, {params:params});
        /*const url = 'https://api.vimeo.com/videos/'+uri+'/pictures';
        const headers = new HttpHeaders({
            'Authorization': 'bearer ' + this.token,
            'Content-Type': 'application/json',
            'Accept': 'application/vnd.vimeo.*+json;version=3.4',
            'Access-Control-Allow-Origin': '*',
        });
        const request = new HttpRequest('GET', url, {}, {headers: headers});*/
        

        return this.http.request(request);
    }

    getVideo(uri: string): Observable<HttpEvent<unknown>>
    {
        let params = new HttpParams();
        params = params.set('uri', uri);
        params = params.set('token', environment.vimeo.token);

        const request = new HttpRequest('GET', environment.vimeo.url + '/getVideo', { uri: uri, token: environment.vimeo.token}, {params:params});
        return this.http.request(request);
    }


    
}

