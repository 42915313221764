import { Injectable } from '@angular/core';

import { User } from '../models/user.model';

@Injectable({
  	providedIn: 'root'
})
export class UserService {

    private user: User;

    constructor() { }

    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
    getUser() : User { return this.user; }

    // -----------------------------------------------------------------------------------------------------
    // @ SETTER
    // -----------------------------------------------------------------------------------------------------
    setUser(user: User) { this.user = user; }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

}