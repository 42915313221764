import { JsonObject, JsonProperty } from "json2typescript";
import { Content } from "./content.model";
import { Interaction } from "./interaction.model";

@JsonObject("Environment")
export class Environment {

	@JsonProperty("Key", String, true)
	Key: string = "";
	@JsonProperty("Interactions", [Interaction], true)
	Interactions : Interaction[] = [];

	constructor() {}

	// -----------------------------------------------------------------------------------------------------
	// @ GETTER
	// -----------------------------------------------------------------------------------------------------
	getInteractions()	: Interaction[] { return this.Interactions; }
	getKey()			: string { return this.Key; }

	// -----------------------------------------------------------------------------------------------------
	// @ SETTER
	// -----------------------------------------------------------------------------------------------------
	setInteractions(interactions: Interaction[])	{ this.Interactions = interactions; }
	setKey(key: string)												{ this.Key = key; }

	// -----------------------------------------------------------------------------------------------------
	// @ PUBLIC METHODS
	// -----------------------------------------------------------------------------------------------------
	addInteraction(interaction: Interaction) {
		this.Interactions.push(interaction);
	}
}