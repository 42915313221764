import { JsonObject, JsonProperty } from "json2typescript";
import { Content } from "./content.model";

@JsonObject("Interaction")
export class Interaction {

     @JsonProperty("Key", String, true)
     Key: string = "";
     @JsonProperty("Contents", [Content], true)
     Contents : Content[] = [];
     @JsonProperty("Type", String, true)
     Type: string = "";

     constructor() {}

     // -----------------------------------------------------------------------------------------------------
     // @ GETTER
     // -----------------------------------------------------------------------------------------------------
     public getKey()     : string { return this.Key; }
     public getContents()     : Content[] { return this.Contents; }
     public getType()         : string { return this.Type; }
 
     // -----------------------------------------------------------------------------------------------------
     // @ SETTER
     // -----------------------------------------------------------------------------------------------------
     public setKey(key: string)              { this.Key = key; }
     public setContents(contents: Content[]) { this.Contents = contents; }
     public setType(type: string)            { this.Type = type; }
 
     // -----------------------------------------------------------------------------------------------------
     // @ Public methods
     // -----------------------------------------------------------------------------------------------------
     addContent(content: Content) {
          this.Contents.push(content);
     }

     changeContent(content: Content) {
          this.Contents[this.Contents.indexOf(content, 0)] = content;
     }

     deleteContent(content: Content) {
          this.Contents.splice(this.Contents.indexOf(content, 0), 1);
     }
}