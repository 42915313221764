import { Scenario } from 'app/models/scenario/scenario.model';
import { JsonConverter, JsonCustomConvert, JsonObject, JsonProperty } from "json2typescript";
import { Diffusion } from './diffusion.model';
import * as moment from 'moment/moment';
import { Attempt } from './attempt.model';

@JsonConverter
class DateConverter implements JsonCustomConvert<Date> {

	// Object -> Json
	serialize(date: Date): string {
		//correction pour pb sur Safari MAC OS
		let month :string="";
		month = (date.getMonth()+1).toString();
		month = (month.length<2) ? "0"+month : month;
		let day :string="";
		day = (date.getDate()).toString();
		day = (day.length<2) ? "0"+day : day;
		
		return date.getFullYear() + "-" + month + "-" +  day;		
	}

	// Json -> Object
	deserialize(date: string): Date {
		//correction pour pb sur Safari MAC OS
		const dt=date.split("-");
		return new Date(parseInt(dt[0]),parseInt(dt[1])-1,parseInt(dt[2]));
	}

}



@JsonObject("SubscriptionUser")
export class Subscription {

    @JsonProperty("Key", String, true)
    private Key: string = "";

    @JsonProperty("DiffusionKey", String, true)
    private DiffusionKey: string = "";

    private Diffusion: Diffusion = undefined;

    @JsonProperty("StartDate", DateConverter, true)
    private StartDate: Date = undefined;
    @JsonProperty("EndDate", DateConverter, true)
    private EndDate: Date = undefined;
    @JsonProperty("Attempts", [Attempt], true)
    private Attempts: Attempt[] = [];
    @JsonProperty("MaxAttempt", Number, true)
    private MaxAttempt: number = undefined;
    @JsonProperty("MainSubscription", Boolean, true)
    private MainSubscription: boolean = false;

    
    constructor() {}
    
    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
	// -----------------------------------------------------------------------------------------------------

    get key() : string { return this.Key; }
    set key(value: string) { this.Key = value; }

    get diffusionKey() : string { return this.DiffusionKey; }
    set diffusionKey(value: string) { this.DiffusionKey = value; }
    
    get diffusion() : Diffusion { return this.Diffusion; }
    set diffusion(value: Diffusion) { this.Diffusion = value; }
    
    get startDate() : Date { return this.StartDate; }
    set startDate(value: Date) { this.StartDate = value; }

    get endDate() : Date { return this.EndDate; }
    set endDate(value: Date) { this.EndDate = value; }

    get attempts() : Attempt[] { return this.Attempts; }
    set attempts(value: Attempt[]) { this.Attempts = value; }

    get maxAttempt() : number { return this.MaxAttempt; }
    set maxAttempt(value: number) { this.MaxAttempt = value; }
    
    get mainSubscription() : boolean { return this.MainSubscription; }
    set mainSubscription(value: boolean) { this.MainSubscription = value; }
    
    
    // -----------------------------------------------------------------------------------------------------
	// @ Public methods
    // -----------------------------------------------------------------------------------------------------
    isOpen(){
        let now : Date = new Date(Date.now());
        return (this.StartDate <= now && now <= this.endDate);
    }
}

