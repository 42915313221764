import { Mask360 } from 'app/models/scenario/event.model';
/* eslint-disable no-case-declarations */
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { LibraryService } from '../library.service';
import { DatabaseService } from '../database.service';

import { Scenario } from 'app/models/scenario/scenario.model';
import { Scene } from 'app/models/scenario/scene.model';
import { EventType, Event, GroupRecognition } from 'app/models/scenario/event.model';
import { HTMLOptions } from 'app/models/scenario/htmlOptions.model';
import { Router } from '@angular/router';
import { StoryOptions } from 'app/models/scenario/storyOptions.model';
import { Condition, ConditionType, Conditions } from 'app/models/scenario/condition.model';
import { TranslocoService } from '@ngneat/transloco';

@Injectable({
    providedIn: 'root'
})
export class ScenarioService {

	private _action = '';
	private _scenario: Scenario;
	scenarioSubject = new Subject<Scenario>();

	private _previewMode = false;
	previewModeSubject = new Subject<boolean>();

	private _mode3d = false;
	mode3dSubject = new Subject<boolean>();

	conditionsSubject = new Subject<Conditions>();


	private _databaseService: DatabaseService;

	private _hasbeenModified = false;

	constructor(
		private _libraryService: LibraryService,
		private _router: Router,
		private _translocoService: TranslocoService
	) {
		if (!this._scenario) {
			this._scenario = new Scenario();
			this._scenario.init("Error Key", "","");
		}
	}


	// -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
	// -----------------------------------------------------------------------------------------------------
	get action() { return this._action; }
	set action(value: string) { this._action = value; }

	get hasBeenModified() { return this._hasbeenModified; }
	set hasBeenModified(value: boolean) { this._hasbeenModified = value; }

	get scenario() { return this._scenario; }
	set scenario(value: Scenario) {
		this._scenario = value;
		this.emitScenario();
	}
    get dbService(): DatabaseService { return this._databaseService; }

	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Emit scene
	 */
	private emitScenario(_databaseService: DatabaseService = undefined) {

        let langs = [];
        let lang = '';
		if(this._databaseService==undefined && _databaseService != undefined)
			this._databaseService=_databaseService;
		if(this._databaseService != undefined)
        {
            if(this._databaseService.user!==undefined)
            {
                langs = this._databaseService.user.Languages;
                lang  = this._databaseService.user.DefaultLang;
            }
			this._scenario.languages = langs;
			if(this._scenario.defaultLanguage === undefined){
				this._scenario.defaultLanguage = lang;
			}
		}
		else if(_databaseService !=undefined)
        {
            if(_databaseService.user!==undefined)
            {
                langs = _databaseService.user.Languages;
                lang = _databaseService.user.DefaultLang
            }
			this._scenario.languages = langs;
			if(this._scenario.defaultLanguage === undefined){
				this._scenario.defaultLanguage = lang;
			}
		}

		//console.log('Scenario service emit'+this._scenario.languages);
		this.scenarioSubject.next(this._scenario);
	}

	updateScenarioVersionDate(_databaseService: DatabaseService = undefined) {

		//console.log('Scenario service update' + this._databaseService);
		if (this._scenario.diffusionKey !== '') {
			if (this._scenario.version !== this._scenario.versionDiffusion + 1) {
				this._scenario.version = this._scenario.versionDiffusion + 1;
				this._databaseService.updateVersion(this._scenario.key, this._scenario.version);
			}
		}
		else {
			if (this._scenario.version !== 1) {
				this._scenario.version = 1;
				this._databaseService.updateVersion(this._scenario.key, this._scenario.version);
			}
		}

		this._scenario.setLastUpdate(Date.now().toString());
		//this.uploadScenario(_databaseService);
		if (this._scenario !== undefined && this._databaseService !== undefined) {
			if (this.scenario.key !== "Error Key")
				this._databaseService.updateDate(this._scenario.key, this.scenario.lastUpdate);
		}
/*		if(this._hasbeenModified)
			this.uploadScenario(_databaseService);*/
	}


	/**
	 * Emit preview mode²
	 */
	private emitPreviewMode() {
		this.previewModeSubject.next(this._previewMode);
	}

	private emitMode3d() {
		this.mode3dSubject.next(this._mode3d);
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Upload scenario
	 */
	uploadScenario(_databaseService: DatabaseService = undefined) {
        //console.log('Scenario service upload'+this._databaseService);
		if(this._databaseService==undefined && _databaseService != undefined)
			this._databaseService=_databaseService;
		if(this._databaseService!=undefined){
			try{
				this._databaseService.uploadScenario(this._scenario);

			}
			catch(error){
					console.error(error);
					this._router.navigate(['pages/scenarios']);
					return;

			}
		}
	}

    updateScene(scenariokey:string,index:number,option:string,val:any)
    {
        this._databaseService.updateScene(scenariokey,index,option,val);
    }
    updateSceneOptions(scenariokey:string,index:number,option:string,val:any,serializeobj:boolean)
    {
        this._databaseService.updateSceneOptions(scenariokey,index,option,val,serializeobj);
    }

	/**
	 * Set information
	 * Should be called before opening the editor
	 *
     *  @param {string} action
     *  @param {Scenario?} scenario
	 */
	setInformations(action: string, scenario: Scenario  = undefined, _databaseService: DatabaseService = undefined) {
		this._action = action;

		if (scenario != undefined) {
			this._scenario = scenario;
		}
		this.emitScenario(_databaseService);
        return
	}

    setScenario(scenario: Scenario){
        this._scenario = scenario;
    }

	/**
	 * Get available scenes
	 *
	 * @returns List of scenes
	 */
	getAvailableScenes() : Scene[] {
		return this._scenario.scenes;
	}

	/**
	 * Get available scenes
	 *
	 * @returns List of scenes
	 */
    getScene(key:string) : Scene {
		return this._scenario.scenes.find(sc=>sc.key===key);
	}

	/**
	 * All actions on scene should pass through this service to emit scene when it changes
	 */

	/**
	 * Get scene is a trigger to emit scene
	 */
	getScenario(databaseService = undefined) {
        //console.log('get scenario (service)');
		this.emitScenario(databaseService);
	}

	/**
	 * Create a scenario
	 *
     *  @param {Scenario} scenario
	 */
	createScenario(scenario: Scenario) {
		this.scenario = scenario;
		this.emitScenario();
		this._hasbeenModified=true;
	}

	/**
	 * Change scenario title
	 *
	 * @param newTitle
	 */
	changeScenarioTitle(newTitle: string ) {
		this._scenario.Title = newTitle;
		this._databaseService.updatestoryOption(this.scenario.key,'Title',newTitle,false);
		this.updateScenarioVersionDate();
	}

    changeScenarioDescription(newDescription: string ) {
		this._scenario.Description = newDescription;
		this._databaseService.updatestoryOption(this.scenario.key,'Description',newDescription,false);
		this.updateScenarioVersionDate();
	}

	/**
	 * Change audio key
	 *
	 * @param audioKey
	 */
	changeAudioKey(audioKey: string ) {
		this._scenario.audioKey = audioKey;
		this._databaseService.updatestoryOption(this.scenario.key,'AudioKey',audioKey,false);
		this.updateScenarioVersionDate();
	}

    changeVolume(volume: number ) {
		this._scenario.volume = volume;
		this._databaseService.updatestoryOption(this.scenario.key,'Volume',volume,false);
		this.updateScenarioVersionDate();
	}

    changeMessage(message: string ) {
		this._scenario.message = message;
		this._databaseService.updatestoryOption(this.scenario.key,'Message',message,false);
		this.updateScenarioVersionDate();
	}

	changePreviewKey(previewKey: string ) {
		this._scenario.Preview = previewKey;
		this._databaseService.updatestoryOption(this.scenario.key,'Preview',previewKey,false);
		this.updateScenarioVersionDate();
	}

	/**
	 * Change Primary Color
	 *
	 * @param audioKey
	 */
	changePrimaryColor(color: string ) {
		this._scenario.primaryColor = color;
		this._databaseService.updatestoryOption(this.scenario.key,'PrimaryColor',color,false);
		this.updateScenarioVersionDate();
	}

	/**
	 * Change Secondary Color
	 *
	 * @param audioKey
	 */
	changeSecondaryColor(color: string ) {
		this._scenario.secondaryColor = color;
		this._databaseService.updatestoryOption(this.scenario.key,'SecondaryColor',color,false);
		this.updateScenarioVersionDate();
	}

	changeBackgroundColor(color: string ) {
		this._scenario.backgroundColor = color;
		this._databaseService.updatestoryOption(this.scenario.key,'BackgroundColor',color,false);
		this.updateScenarioVersionDate();
	}

	changeAutoStart(auto: boolean ) {
		this._scenario.autoStart = auto;
		this._databaseService.updatestoryOption(this.scenario.key,'AutoStart',auto,false);
		this.updateScenarioVersionDate();
	}

    changePause(pause:boolean ){
        this._scenario.pauseBtn = pause;
		this._databaseService.updatestoryOption(this.scenario.key,'PauseBtn',pause,false);
    }
	changeScenes(scenes: Scene[] ){
		this._scenario.scenes = scenes;
		this.updateScenarioVersionDate();
	}

	/**
	 * Change if htmlTemplate should be showed or not
	 *
	 * @param value
	 */
	changeHtmlTemplate(value: boolean){
		this._scenario.htmlTemplate = value;
		this._databaseService.updatestoryOption(this.scenario.key,'HtmlTemplate',value,false);
		this.updateScenarioVersionDate();
	}

	/**
	 * Change HtmlOptions for the html template
	 *
	 * @param value
	 */
	changeHtmlOptions(value: HTMLOptions){
		this._scenario.htmlOptions = value;
		this._databaseService.updatestoryOption(this.scenario.key,'HtmlOptions',value,true);
		this.updateScenarioVersionDate();
    }

	changeGpxKey(key: string){
		this._scenario.gpxKey = key;
		this._databaseService.updatestoryOption(this.scenario.key,'GpxKey',key,false);
		this.updateScenarioVersionDate();
	}

	changeLimit(min : number, max: number ){
		this._scenario.limitMin = min;
		this._scenario.limitMax = max;
		this._databaseService.updatestoryOption(this.scenario.key,'LimitMin',min,false);
		this._databaseService.updatestoryOption(this.scenario.key,'LimitMax',max,false);
		this.updateScenarioVersionDate();
	}

    setTags(tags){
		this._scenario.setTags(tags);
	}
	setQuestionsSets(questionsSets){
		this._scenario.questionsSets = questionsSets;
		this._databaseService.updatequestionSets(this.scenario.key,'QuestionSets',questionsSets);
		this.updateScenarioVersionDate();
	}

	setVariables(variables){
		this._scenario.variables = variables;
		this._databaseService.updateVariables(this.scenario.key,'Variables',variables);
		this.updateScenarioVersionDate();
	}

	setStoryOptions(value: StoryOptions){
		this._scenario.storyOptions = value;
		this._databaseService.updatestoryOption(this.scenario.key,'StoryOptions',value,true);
		this.updateScenarioVersionDate();
	}

	setDefaultLanguage(value: string){
		this._scenario.defaultLanguage = value;
		this._databaseService.updatestoryOption(this.scenario.key,'DefaultLanguage',value,false);
	}

	setLanguages(value: string[]){
		this._scenario.languages = value;
		this._databaseService.updatestoryLanguages(this.scenario.key,'Languages',value);

	}



	/**
	 * Reset scenario
	 *
	 * Should be called when the user leaves the editor
	 */
	resetScenario() {
		this._scenario = new Scenario();
		this._scenario.init("Error Key", "","");
        //console.log('resetScenario');
		this._hasbeenModified=false;
		this.emitScenario();
	}

    /**
     * Add scene to current scenario
     *
     *  @param scene
     */
    addScene(scene: Scene, after: Scene = undefined) {
		this._scenario.addScene(scene,after);
        //console.log('addScene');

		this._databaseService.updateSceneList(this.scenario.key, this.scenario.scenes);

		this.emitScenario();



    }

    /**
     * Main scene is
     *
     * @param {Scene} scene
     */
    mainSceneIs(scene: Scene) {
        this._scenario.mainSceneIs(scene);
        //console.log('mainSceneIs');

		this._databaseService.updateSceneList(this.scenario.key, this.scenario.scenes);

		this.emitScenario();
		//this.updateScenarioVersionDate();
	}

    /**
     * Delete scene
     *
     * @param scene
     */
    delete(scene: Scene) {
		this._scenario.delete(scene);
        //console.log('delete');

		this._databaseService.updateSceneList(this.scenario.key, this.scenario.scenes);

		this.emitScenario();

		//this.updateScenarioVersionDate();
	}

	/**
	 * Activate preview mode
	 */
	activatePreviewMode() {
		this._previewMode = true;
		this.emitPreviewMode();
	}

	activateMode3d() {
		this._mode3d = true;
		this.emitMode3d();
	}

	desactivateMode3d() {
		this._mode3d = false;
		this.emitMode3d();
	}

	/**
	 * Desactivate preview mode
	 */
	desactivatePreviewMode() {
		this._previewMode = false;
		this.emitPreviewMode();
	}

	// Personnaliser un peu plus les erreurs pour savoir d'où elles viennent

    /**
     * Is the scenario ready for diffusion
     *
     * @returns True is the scenario is ready or False with a list of errors
     */
    readyForDiffusion(scenario: Scenario,service :DatabaseService=undefined) : {isReady: boolean, errors: string[]} {

		const globalErrors: string[] = [];
		if(this._databaseService===undefined && service!==undefined)
			this._databaseService=service;

		if(this._libraryService.library===undefined || this._libraryService.library===null)
			{
				console.log("Library is undefined!");
				console.log(this._databaseService.library);
				if(this._databaseService.library!==undefined)
					this._libraryService.setLibrary(this._databaseService.library);
				else
				{
					setTimeout(() => {
						this.readyForDiffusion(scenario,service);
					}, 1000);
					return;
				}
			}

			scenario.scenes.forEach((scene: Scene) => {

			const sceneMedia = this._libraryService.getContentsForKeys([scene.mediaKey]);
			if (sceneMedia == undefined || sceneMedia.length == 0) {
				globalErrors.push("Pas de media pour la scène");
			}

			if(scenario.Preview!="")
			{
				const sceneMedia = this._libraryService.getContentsForKeys([scenario.Preview]);
				if (sceneMedia == undefined || sceneMedia.length == 0) {
					globalErrors.push("Preview non défini");
				}
			}

			if(scenario.audioKey!="")
			{
				const storyMediaAudio = this._libraryService.getContentsForKeys([scenario.audioKey]);
				if (storyMediaAudio == undefined || storyMediaAudio.length == 0) {
					globalErrors.push("Audio non défini");
				}
			}

			if(scene.sceneOptions.audioKey!="" && scene.sceneOptions.audioKey!=undefined)
			{
				const sceneAudioMedia = this._libraryService.getContentsForKeys([scene.sceneOptions.audioKey]);
				if (sceneAudioMedia == undefined || sceneAudioMedia.length == 0) {
					globalErrors.push("Pas de media audio pour la scène");
				}
			}

			if(scene.sceneOptions.obj3DKey!="" && scene.sceneOptions.obj3DKey!=undefined)
			{
				const sceneObjMedia = this._libraryService.getContentsForKeys([scene.sceneOptions.obj3DKey]);
				if (sceneObjMedia == undefined || sceneObjMedia.length == 0) {
					globalErrors.push("Pas d'objet 3D pour la scène");
				}
			}

			scene.events.forEach((event: Event) => {

				const error = this.isEventCorrect(scenario, event);

				if (error.length != 0) {
					globalErrors.push(error);
				}

			});

		});

		if (globalErrors.length != 0) {
			return {
				isReady: false,
				errors: globalErrors
			};
		} else {
			return {
				isReady: true,
				errors: []
			};
		}

	}
	setDatabaseService(databaseService : DatabaseService){
        //console.log('-------------------------------Set db service------------------------------');
    //    this._databaseService = databaseService;
    }

	conditionChanged(conditions: Conditions) {
		this.conditionsSubject.next(conditions);
	}

	checkCond(cond: Condition): boolean
	{
		switch(cond.type)
		{
			case ConditionType.Condition:
				if(
					cond?.params==undefined
                    || cond?.params["cond1"]==undefined
					|| cond?.params["cond2"]==undefined
					|| !this.checkCond(cond.params["cond1"])
					|| !this.checkCond(cond.params["cond2"])
				){
					return false;
				}
				break;
			case ConditionType.OpenEvent:
			case ConditionType.CloseEvent:
				if(cond.params===undefined)
					return false;
				if(
					cond.params["sceneKey"]===undefined
					|| cond.params["eventKey"]===undefined
					|| cond.params["condOp"]===undefined
					|| cond.params["condNum"]===undefined
					|| cond.params["sceneKey"]=== ''
					|| cond.params["eventKey"]=== ''
					|| cond.params["condOp"]=== ''
					|| cond.params["condNum"]=== -1
				){
					return false;
				}
				break;
			case ConditionType.Question:
				if(cond.params===undefined)
					return false;
				if(
					cond.params["sceneKey"]===undefined
					|| cond.params["eventKey"]===undefined
					|| cond.params["answerKey"]===undefined
					|| cond.params["sceneKey"]=== ''
					|| cond.params["eventKey"]=== ''
					|| cond.params["answerKey"]=== -1
				){
					return false;
				}
				break;
			case ConditionType.QuestionStatus:
				if(cond.params===undefined)
					return false;
				if(
					cond.params["sceneKey"]===undefined
					|| cond.params["eventKey"]===undefined
					|| cond.params["status"]===undefined
					|| cond.params["sceneKey"]=== ''
					|| cond.params["eventKey"]=== ''
					|| cond.params["status"]=== -1
				){
					return false;
				}
				break;
				case ConditionType.SceneOpen:
					if(cond.params===undefined)
						return false;
					if(
						cond.params["sceneKey"]===undefined
					){
						return false;
					}
					break;
			case ConditionType.Questionnaire:
				if(cond.params===undefined)
					return false;
				if(
					cond.params["questionsSetKey"]===undefined
					|| cond.params["PourcentMin"]===undefined
					|| cond.params["PourcentMax"]===undefined
					|| cond.params["questionsSetKey"]=== ''
					|| cond.params["PourcentMin"]=== -1
					|| cond.params["PourcentMax"]=== -1
				){
					return false;
				}
				break;
                case ConditionType.Formateur:
                    return true;
                    break;
				case ConditionType.PlayOn:
						return true;
						break;
			case ConditionType.Variable:
				if(cond.params===undefined)
					return false;
				if(cond.params["variableName"]===undefined
				|| cond.params["variableValue"]=== undefined
				|| cond.params["variableValue"]=== '')
					return false;
					break;

		}
		return true;
	}


	/**
	 * Check errors for an event
	 *
	 * @param scenario
	 * @param event
	 *
	 * @returns String in case of error or ""
	 */
	isEventCorrect(scenario: Scenario, event: Event) : string {
		if(event==undefined)
			return "event undefined !";
		if(event.conditions?.conditions?.length>0)
		{
			for(const cond of event.conditions.conditions)
			{
				if(!this.checkCond(cond)){
					return this._translocoService.translate("ERROR.EVENT.1");// "";
				}
			}
		}
		switch (event.type) {
			case EventType.SceneLink:
				if (event.properties.DestinationKey.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.2");//"Pas de destination sélectionnée";
				}

				const availableScene = scenario.scenes;

				const destination = availableScene.filter((scene) => {
					return scene.key == event.properties.DestinationKey;
				});

				if (destination.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.3");//"";
				}

				break;
            case EventType.StoryLink:
                if (event.properties.DestinationKey.length == 0) {
                    return this._translocoService.translate("ERROR.EVENT.2");//"Pas de destination sélectionnée";
                }

                const availableDiffusion = this._databaseService.diffusions;

                const dest = availableDiffusion.filter((diffusion) => {
                    return diffusion.key == event.properties.DestinationKey;
                });

                if (dest.length == 0) {
                    return this._translocoService.translate("ERROR.EVENT.3");//"La destination sélectionnée n'est plus disponible";
                }

                break;

            case EventType.ContentLink:
                if (event.properties.ContentKey == undefined || event.properties.ContentKey.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.6");//"Pas de média sélectionnée";
				}

				const content = this._libraryService.getContentsForKeys([event.properties.ContentKey]);
				if (content == undefined) {
					return this._translocoService.translate("ERROR.EVENT.7");//"Le média sélectionnée n'est plus disponible";
				}

				if (content.filter(element => {
					if (element.getType() != "Standard" || (element.category != "Gps" && element.category != "PDF")) {
						return false;
					} else {
						return true;
					}
				}).length == 0) {
					return this._translocoService.translate("ERROR.EVENT.7");//"Le média sélectionnée n'est plus disponible";
				}

                break;
			case EventType.Text:
				if (
						(event.properties.Text == undefined || event.properties.Text.length == 0 )
						&&
						(event.properties.TextHeader == undefined || event.properties.TextHeader.length == 0)
					) {
					return this._translocoService.translate("ERROR.EVENT.9");//"Erreur de texte";
				}
				break;

			case EventType.Image:
				if (event.properties.Image == undefined || event.properties.Image.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.10");//"Pas d'image sélectionnée";
				}

				const imageContent = this._libraryService.getContentsForKeys([event.properties.Image]);
				if (imageContent == undefined) {
					return this._translocoService.translate("ERROR.EVENT.11");//"L'image sélectionnée n'est plus disponible";
				}

				if (imageContent.filter(element => {
					if (element.getType() != "Standard" || element.category != "Image") {
						return false;
					} else {
						return true;
					}
				}).length == 0) {
					return this._translocoService.translate("ERROR.EVENT.11");//"L'image sélectionnée n'est plus disponible";
				}

				break;
			case EventType.Mask360:
				if (event.properties.Image == undefined || event.properties.Image.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.10");//"Pas d'image sélectionnée";
				}

				const imeContent = this._libraryService.getContentsForKeys([event.properties.Image]);
				if (imeContent == undefined) {
					return this._translocoService.translate("ERROR.EVENT.11");//"L'image sélectionnée n'est plus disponible";
				}

				if (imeContent.filter(element => {
					if(element.category != "Image")
						return false;
					else{
						if(element.getType()=="360Monoscopic" || element.getType()=="360Side" || element.getType()=="360TopBottom")
							return true;
						else
							return false;
					}
					
				}).length == 0) {
					return this._translocoService.translate("ERROR.EVENT.11");//"L'image sélectionnée n'est plus disponible";
				}
				break;
			case EventType.Diaporama:
				if (event.properties.Images == undefined || event.properties.Images.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.10");//"Pas d'image sélectionnée";
				}
				for (let index = 0; index <  event.properties.Images.length; index++) {
					const img =  event.properties.Images[index];

					const imageDiapo = this._libraryService.getContentsForKeys([img]);
					if (imageDiapo == undefined) {
						return this._translocoService.translate("ERROR.EVENT.11");//"L'image sélectionnée n'est plus disponible";
					}
					if (imageDiapo.filter(element => {
						if (element.getType() != "Standard" || element.category != "Image") {
							return false;
						} else {
							return true;
						}
					}).length == 0) {
						return this._translocoService.translate("ERROR.EVENT.11");//"L'image sélectionnée n'est plus disponible";
					}
				}


				break;

			case EventType.Video:
				if (event.properties.Video == undefined || event.properties.Video.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.16");//"Pas de vidéo sélectionnée";
				}

				const videoContent = this._libraryService.getContentsForKeys([event.properties.Video]);
				if (videoContent == undefined) {
					return this._translocoService.translate("ERROR.EVENT.17");//"L'image sélectionnée n'est plus disponible"
				}

				if (videoContent.filter(element => {
					if (element.getType() != "Standard" || element.category != "Video") {
						return false;
					} else {
						return true;
					}
				}).length == 0) {
					return this._translocoService.translate("ERROR.EVENT.17");//"La vidéo sélectionnée n'est plus disponible";
				}

				break;

			case EventType.Audio:
				if (event.properties.Audio == undefined || event.properties.Audio.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.19");//"Pas d'audio sélectioné";
				}

				const audioContent = this._libraryService.getContentsForKeys([event.properties.Audio]);
				if (audioContent == undefined) {
					return this._translocoService.translate("ERROR.EVENT.20");//"L'audio sélectionné n'est plus disponible"
				}

				if (audioContent.filter(element => {
					if (element.getType() != "Standard" || element.category != "Audio") {
						return false;
					} else {
						return true;
					}
				}).length == 0) {
					return this._translocoService.translate("ERROR.EVENT.20");//"L'audio sélectionné n'est plus disponible";
				}

				break;

			case EventType.WebLink:
				if (event.properties.Url == undefined || event.properties.Url.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.4");//"Pas d'url";
				}
				break;

			case EventType.Zone:
				break;

			case EventType.QCM:

				if (event.properties.Question == undefined || event.properties.Question.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.5");//"Pas de question";
				}

				if (event.properties.Answers.length < 2) {
					return this._translocoService.translate("ERROR.EVENT.8");//"Vous devez avoir au moins deux réponses possibles";
				}

				for (let i = 0; i < event.properties.Answers.length; i++) {
					if (event.properties.AnswerText && event.properties.Answers[i].Text.length == 0) {
						return this._translocoService.translate("ERROR.EVENT.12");//return "La réponse " + (i + 1).toString() + " ne contient pas de texte";
					}
					if (!event.properties.AnswerText && (event.properties.Answers[i].Key == undefined || event.properties.Answers[i].Key.length == 0)) {
						return this._translocoService.translate("ERROR.EVENT.13");//return "La réponse " + (i + 1).toString() + " ne contient pas d'image'";
					}
				}

				break;

			case EventType.QCU:

				if (event.properties.Question.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.5");//return "Pas de question"
				}

				let numberOfCheckedAnswers = 0
				for (let i = 0; i < event.properties.Answers.length; i++) {
					if (event.properties.Answers[i].Checked)
						numberOfCheckedAnswers++;
				}

				if (numberOfCheckedAnswers == 0) {
					return this._translocoService.translate("ERROR.EVENT.14");//return "Vous n'avez pas de réponse possible";
				}

				if (event.properties.Answers.length < 2) {
					return this._translocoService.translate("ERROR.EVENT.8");//return "Vous devez avoir au moins deux réponses possibles";
				}

				for (let i = 0; i < event.properties.Answers.length; i++) {
					if (event.properties.AnswerText && event.properties.Answers[i].Text.length == 0) {
						return this._translocoService.translate("ERROR.EVENT.12");//return "La réponse " + (i + 1).toString() + " ne contient pas de texte";
					}
					if (!event.properties.AnswerText && (event.properties.Answers[i].Key == undefined || event.properties.Answers[i].Key.length == 0)) {
						return this._translocoService.translate("ERROR.EVENT.13");//return "La réponse " + (i + 1).toString() + " ne contient pas d'image'";
					}
				}

				break;
            case EventType.QCO:

                if (event.properties.Question.length == 0) {
                    return this._translocoService.translate("ERROR.EVENT.5");//return "Pas de question"
                }


                if (event.properties.AnswerText == undefined || event.properties.AnswerText.length == 0) {
                    return this._translocoService.translate("ERROR.EVENT.15");//return "La réponse ne contient pas de texte";
                }

                break;

			case EventType.QuizHotspot:

				if (event.properties.Question.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.5");//return "Pas de question"
				}

				if (event.properties.HotspotAnswers.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.18");//return "Vous devez avoir au moins un objet caché"
				}

				break;
			case EventType.Consigne:
				if(event.properties.Title.length == 0 && event.properties.Text.length == 0 && (event.properties.Image == undefined || event.properties.Image.length ==0)){
					return this._translocoService.translate("ERROR.EVENT.21");//return "Un de ces trois champs ne doit pas être vide ! (Titre, Texte, Média)";
				}
				if(event.properties.Button.length == 0)
					return this._translocoService.translate("ERROR.EVENT.22");//return "Le bouton ne doit pas être vide !";
			break;
			case EventType.Objet3D:
				if(event.properties.MixtReality===undefined || event.properties.MixtReality=== false)
				{
					if (event.properties.ContentKey == undefined || event.properties.ContentKey.length == 0) {
						
							return this._translocoService.translate("ERROR.EVENT.23");//return "Pas d'Objet3D sélectionné";
					}

					const obContent = this._libraryService.getContentsForKeys([event.properties.ContentKey]);
					if (obContent == undefined || obContent.length === 0) {
						return this._translocoService.translate("ERROR.EVENT.24");//return "L'objet sélectionné n'est plus disponible";
					}
				}
				break;
			case EventType.Avatar:
				if (event.properties.ContentKey == undefined || event.properties.ContentKey.length == 0) {
					if(event.properties.EventLinked == undefined || event.properties.EventLinked.length == 0)
						return this._translocoService.translate("ERROR.EVENT.23");//return "Pas d'Objet3D sélectionné";
				}

				if(event.properties.EventLinked == undefined || event.properties.EventLinked.length == 0)
				{
					const avContent = this._libraryService.getContentsForKeys([event.properties.ContentKey]);
					if (avContent == undefined || avContent.length === 0) {
						return this._translocoService.translate("ERROR.EVENT.24");//return "L'objet sélectionné n'est plus disponible";
					}
				}
				else
				{

				}

				/*if (event.properties.Audio == undefined || event.properties.Audio.length == 0) {
					return this._translocoService.translate("ERROR.EVENT.19");//return "Pas d'Objet3D sélectionné";
				}

				const avContent2 = this._libraryService.getContentsForKeys([event.properties.Audio]);
				if (avContent2 == undefined || avContent2.length === 0) {
					return this._translocoService.translate("ERROR.EVENT.20");//return "L'objet sélectionné n'est plus disponible";
				}*/
				break;
			case EventType.HeadsetSetting:
					if(event.properties.Parameters.length == 0)
					return this._translocoService.translate("ERROR.EVENT.25");//return "Aucun paramètre défini";
                        break;

			case EventType.VoiceRecognition:
				if (event.properties.groups === undefined || event.properties.groups.length === 0) {
					return this._translocoService.translate("ERROR.EVENT.26");//return "Pas de liste de mots";
				}
				for (let index = 0; index <  event.properties.groups.length; index++) {
					const g : GroupRecognition =  event.properties.groups[index];

					if(g.words === undefined || g.words.length === 0)
					{
						return this._translocoService.translate("ERROR.EVENT.27");//return "Une liste ne contient pas de mots";
					}
				}

				break;

			default:
				return "";
		}

		return "";
	}



	getScormId(): number
	{
		const id = this.scenario.scormID++;
		this.scenario.scormID = id;
		this.emitScenario();
		return id;
	}

	InvertScene(index1: number,modifiedSc1: Scene,index2: number,modifiedSc2: Scene) {

		this._scenario.scenes[index1] = modifiedSc1;
		this._scenario.scenes[index2] = modifiedSc2;

		this._databaseService.updateAllScene(this.scenario.key,index1,modifiedSc1);
		this._databaseService.updateAllScene(this.scenario.key,index2,modifiedSc2);
	}


}
