import { JsonObject, JsonProperty } from "json2typescript";
@JsonObject("VimeoFile")
export class VimeoFile{
    @JsonProperty("name", String, true)
    name: string = '';
    @JsonProperty("url", String, true)
    url: string = '';
    @JsonProperty("quality", String, true)
    quality : string = '';
    @JsonProperty("type", String, true)
    type: string = '';

}



@JsonObject("VimeoData")
export class VimeoData {
    @JsonProperty("uri", String, true)
	uri : string = '';
    @JsonProperty("status", String, true)
	status : string = 'in_progress';
    @JsonProperty("files", [VimeoFile], true)
	files : VimeoFile[] = [];
}

