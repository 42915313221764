import { FuseNavigationItem } from '@fuse/components/navigation';

export const defaultNavigation: FuseNavigationItem[] = [
    {
        id: 'Admin',
        title: "ADMINISTRATION",
        icon: 'supervised_user_circle',
        type: 'group',
        children: [
            {
                id   : 'register',
                title: 'Gestion des comptes',
                type : 'basic',
                icon : 'supervised_user_circle',
                link : '/admin-register'
            },
        ]
    },
    {
        id: 'pages',
        title: "CREATION",
        icon: 'photo_library',
        type: 'group',
        children: [
            {
                id   : 'content',
                title: 'Bibliothèque',
                type : 'basic',
                icon : 'photo_library',
                link : '/pages/content'
            },
            {
                id   : 'scenarios',
                title: 'Stories',
                type : 'basic',
                icon : 'slideshow',
                link : '/pages/scenarios'
            }
        ]
    },
     {
        id: 'diffusion',
        title: "DIFFUSION",
        icon: 'computer',
        type: 'group',
        children: [
            {
                id   : 'web',
                title: 'Publication',
                type : 'basic',
                icon : 'computer',
                link : '/pages/diffusion'
            }

        ]
    },

    {
        id: 'gestion',
        title: "GESTION",
        icon: 'group',
        type: 'group',
        children: [
            {
                id   : 'pairing',
                title: 'Mes Appareils',
                type : 'basic',
                icon : 'devices',
                link : '/pages/match'
            },
            {
                id   : 'projectsV2',
                title: 'Projet',
                type : 'basic',
                icon : 'view_module',
                link : '/pages/projectsV2'
            },
            {
                id   : 'devices',
                title: 'Mes box',
                type : 'basic',
                icon : 'work_outline',
                link : '/pages/devices'
            },
            {
                id   : 'interface',
                title: 'Interface',
                type : 'basic',
                icon : 'image',
                link : '/pages/interface'
            },
            {
                id   : 'myusers',
                title: 'Mes utilisateurs',
                type : 'basic',
                icon : 'group',
                link : '/pages/myusers'
            },
            {
                id   : 'pricing',
                title: 'Mon abonnement',
                type : 'basic',
                icon : 'card_membership',
                link : '/pages/pricing'
            }
        ]
    },
    {
        id: 'report',
        title: 'Rapport',
        icon : 'group',
        type: 'group',
        children: [
            {
                id   : 'stats',
                title: 'Statistiques',
                type : 'basic',
                icon : 'analytics',
                link  : '/pages/report'
            },
            {
                id   : 'form',
                title: 'Formulaire',
                type : 'basic',
                icon : 'table_view',
                link : '/pages/form'
            }
        ]
    },
    {
        id: 'help',
        title: 'Aide',
        icon : 'group',
        type: 'group',
        children: [
            {
                id   : 'wiki',
                title: 'Documentation',
                type : 'basic',
                icon : 'support',
                link : '/pages/wiki'
            },
            {
                id   : 'support',
                title: 'Support',
                type : 'basic',
                icon : 'support',
                link : '/pages/support'
            }
        ]
    }


];
