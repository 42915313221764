import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TutorialService {

    constructor() {
        console.log('TutorialService');
    }

    //subject for tutorial
    tutorialSubject = new Subject<string>();

    emitTutoriel(tutorial: string){
        this.tutorialSubject.next(tutorial);
    }
}
