import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { DatabaseService } from './database.service';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { User, UserType } from 'app/models/user.model';
import { from, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthService } from 'app/core/auth/auth.service';

@Injectable({
  	providedIn: 'root'
})
export class AdminGuardService implements CanActivate, CanActivateChild {

	constructor(
		private _router: Router,
		private _authService: AuthService,
		private _databaseService: DatabaseService
	) { }

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
		const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
	}

	canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): any
    {
		const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
        return this._check(redirectUrl);
	}

	/**
     * Can load
     *
     * @param route
     * @param segments
     */
	 canLoad(route: Route, segments: UrlSegment[]): any
	 {
		 return this._check('/');
	 }


	 /**
     * Check the authenticated status
     *
     * @param redirectURL
     * @private
     */
    private _check(redirectURL: string): any
    {
    // Check the authentication status
        return this._authService.check()
        .pipe(
            switchMap((authenticated) => {
                
                // If the user is not authenticated...
                if ( !authenticated || this._databaseService.user == undefined || this._databaseService.user.Type != UserType.Admin)
                {
                    // Redirect to the sign-in page
                    this._router.navigate(['pages/content']);

                    // Prevent the access
                    return of(false);
                }

                // Allow the access
                return of(true);
            })
        );
    }
}
