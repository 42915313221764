import { DatabaseService } from 'app/services/database.service';
import { Injectable } from '@angular/core';
import { confirmPasswordReset, createUserWithEmailAndPassword, sendPasswordResetEmail, signInWithEmailAndPassword, signOut, updateEmail, updatePassword, updateProfile } from 'firebase/auth';


@Injectable({
    providedIn: 'root'
})
export class AuthService {

	constructor() {}

    database : DatabaseService
		
    init(database)
    {
        this.database=database;
    }
	createNewUser(email: string, password: string, username: string) {
		return new Promise(
		(resolve, reject) => {
			createUserWithEmailAndPassword(this.database.auth, email, password).then(
			() => {
				
				updateProfile(this.database.auth.currentUser,{
					displayName: username
				});
				resolve(undefined);
			}, 
			(error) => {
				console.log(error);
				reject(error);
			}
			);
		}
		);
	}

	signInUser(email: string, password: string) {
		return new Promise(
		(resolve, reject) => {
			signInWithEmailAndPassword(this.database.auth, email, password).then(
			() => {
				resolve(undefined);
			},
			(error) => {
				reject(error);
			}
			);
		}
		);
	}

	signOutUser() {
		return new Promise(
		(resolve, reject) => {
			signOut(this.database.auth).then(
			() => {
				resolve(undefined);
			},
			(error) => {
				reject(error);
			}
			);
		}
		);
	}

	updateEmail(email: string) {
		const user = this.database.auth.currentUser;
		return new Promise(
			(resolve, reject) => {
				updateEmail(user,email).then(
					() => {
						resolve(undefined);
					},
					(error) => {
						reject(error);
					}
				);
			}
		);
	}

	changePassword(newPassword: string) {
		const user = this.database.auth.currentUser;
		return new Promise(
			(resolve, reject) => {
				updatePassword(user,newPassword).then(
					() => {
						resolve(undefined);
					},
					(error) => {
						reject(error);
					}
				);
			}
		);
	}

	sendResetPasswordEmail(email: string) {
		return new Promise(
			(resolve, reject) => {
				sendPasswordResetEmail(this.database.auth, email).then(
					() => {
						resolve(undefined);
					},
					(error) => {
						reject(error);
					}
				);
			}
		);
	}

	passwordReset(code: string, newPassword: string) {
		return new Promise(
			(resolve, reject) => {
				confirmPasswordReset(this.database.auth, code, newPassword).then(
					() => {
						resolve(undefined);
					},
					(error) => {
						reject(error);
					}
				);
			}
		);
	}

	getUID(){
		return this.database.auth.currentUser.uid;
	}

	getEmail() {
		return this.database.auth.currentUser.email;
	}

    getProvider()
    {
        //console.log(this.database.auth.currentUser.providerId);
        return this.database.auth.currentUser;
    }
}
