import { JsonObject, JsonProperty } from "json2typescript";

/**
 * Marker Data
 */
@JsonObject("MarkerData")
export class MarkerData {
    
    @JsonProperty("Latitude", Number, true)
    private Latitude: number = null;
    
    @JsonProperty("Longitude", Number, true)
    private Longitude: number = null;

    @JsonProperty("ImgUrl", String, true)
    private ImgUrl: string = "assets/icons/pointer_map.webp";
    
    @JsonProperty("Width", Number, true)
    private Width: number = 32.5;
    
    @JsonProperty("Height", Number, true)
    private Height: number = 50;
    
    @JsonProperty("AnchorX", Number, true)
    private AnchorX: number = 16.25;
    
    @JsonProperty("AnchorY", Number, true)
    private AnchorY: number = 50;

    @JsonProperty("KeyScene", String, true)
    private KeyScene: string = "";

    @JsonProperty("IsShown", Boolean, true)
    private IsShown: boolean = false;

    constructor(latitude?: number, longitude?: number){
        if(latitude) this.latitude = latitude;
        if(longitude) this.longitude = longitude;
    }

    // -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
    // -----------------------------------------------------------------------------------------------------

    get latitude(): number { return this.Latitude; }
    set latitude(value: number) { this.Latitude = value; }

    get longitude(): number { return this.Longitude; }
    set longitude(value: number) { this.Longitude = value; }

    get imgUrl(): string { return this.ImgUrl; }
    set imgUrl(value: string) { this.ImgUrl = value; }

    get width(): number { return this.Width; }
    set width(value: number) { this.Width = value; }

    get height(): number { return this.Height; }
    set height(value: number) { this.Height = value; }

    get anchorX(): number { return this.AnchorX; }
    set anchorX(value: number) { this.AnchorX = value; }

    get anchorY(): number { return this.AnchorY; }
    set anchorY(value: number) { this.AnchorY = value; }

    get keyScene(): string { return this.KeyScene; }
    set keyScene(value: string) { this.KeyScene = value; }

    get isShown(): boolean { return this.IsShown; }
    set isShown(value: boolean) { this.IsShown = value; }

    // -----------------------------------------------------------------------------------------------------
	// @ Public methods
    // -----------------------------------------------------------------------------------------------------

    public hasLatLngSet(){
        return this.latitude && this.longitude;
    }

    public getCopy(){
        let markerData = new MarkerData(this.latitude, this.longitude);
        markerData.imgUrl = this.imgUrl;
        markerData.anchorX = this.anchorX;
        markerData.anchorY = this.anchorY;
        markerData.width = this.width;
        markerData.height = this.height;
        markerData.keyScene = this.keyScene;
        markerData.isShown = this.isShown;
        return markerData;
    }

}