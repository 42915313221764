import { JsonObject, JsonProperty } from "json2typescript";
import { Content } from './content.model';
import { Tag } from './tag.model';
import { TranslocoService } from "@ngneat/transloco";

@JsonObject("Library")
export class Library {


    @JsonProperty("Key", String, true)
    Key: string = "";
    @JsonProperty("Contents", [Content], true)
    Contents: Content[] = [];
    @JsonProperty("Tags", [Tag], true)
    Tags: Tag[] = [];
    @JsonProperty("DiffusionTags", [Tag], true)
    DiffusionTags: Tag[] = [];
    @JsonProperty("LastUpdate", String, true)
    LastUpdate: string = Date.now().toString();

    constructor(
    ) { }

    get tags() { return this.Tags; }
    get contents() { return this.Contents; }

    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
    getKey(): string { return this.Key; }
    getContents(): Content[] { return this.Contents; }
    getTags(): Tag[] { return this.Tags; }
    getDiffusionTags(): Tag[] { return this.DiffusionTags; }
    getLastUpdate(): string { return this.LastUpdate; }

    // -----------------------------------------------------------------------------------------------------
    // @ SETTER
    // -----------------------------------------------------------------------------------------------------
    setKey(key: string) { this.Key = key; }
    setContents(contents: Content[]) { this.Contents = contents; }
    setDiffusionTags(DiffusionTags: Tag[]) { this.DiffusionTags = DiffusionTags; }
    setLastUpdate(lastUpdate: string) { this.LastUpdate = lastUpdate; }

    // -----------------------------------------------------------------------------------------------------
    // @ PUBLIC METHODS
    // -----------------------------------------------------------------------------------------------------

    setTags(Tags: Tag[],none: string) {
        this.Tags = [];
        for (const t of Tags) {
            if (t.Name != none) {
                this.Tags[this.Tags.length] = t;
            }
        }
    }
    addContent(content: Content) {
        this.Contents.push(content);
    }

    changeContent(content: Content) {

        this.Contents[this.Contents.indexOf(content, 0)] = content;
    }

    deleteContent(content: Content) {
        this.Contents.splice(this.Contents.indexOf(content, 0), 1);
    }
    addTag(t: Tag) {
        this.Tags.push(t);
    }
    addDiffusionsTag(t: Tag) {
        this.DiffusionTags.push(t);
    }
    getContent(contentKey: String) {
        for (let i = 0; i < this.Contents.length; i++) {
            if (this.Contents[i].getKey() == contentKey) {
                return this.Contents[i];
            }
        }
        return null;
    }
}

