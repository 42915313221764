import { FuseNavigationItem } from './../../../@fuse/components/navigation/navigation.types';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Navigation } from 'app/core/navigation/navigation.types';
import { defaultNavigation } from 'app/navigation/navigation';
import { cloneDeep } from 'lodash-es';

@Injectable({
    providedIn: 'root'
})
export class NavigationService
{
    private _navigation: ReplaySubject<Navigation> = new ReplaySubject<Navigation>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient)
    {
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get all navigation data
     */
    get()//: Observable<Navigation>
    {
        let nav : Navigation = {
            default: defaultNavigation,
            compact: defaultNavigation,
            futuristic : defaultNavigation,
            horizontal : defaultNavigation
        }
        /*nav.default= defaultNavigation;
          // Fill compact navigation children using the default navigation
          nav.compact.forEach((compactNavItem) => {
            nav.default.forEach((defaultNavItem) => {
                if ( defaultNavItem.id === compactNavItem.id )
                {
                    compactNavItem.children = cloneDeep(defaultNavItem.children);
                }
            });
        });

        // Fill futuristic navigation children using the default navigation
        nav.futuristic.forEach((futuristicNavItem) => {
            nav.default.forEach((defaultNavItem) => {
                if ( defaultNavItem.id === futuristicNavItem.id )
                {
                    futuristicNavItem.children = cloneDeep(defaultNavItem.children);
                }
            });
        });

        // Fill horizontal navigation children using the default navigation
        nav.horizontal.forEach((horizontalNavItem) => {
            nav.default.forEach((defaultNavItem) => {
                if ( defaultNavItem.id === horizontalNavItem.id )
                {
                    horizontalNavItem.children = cloneDeep(defaultNavItem.children);
                }
            });
        });*/
        this._navigation.next(nav);
        
    }
}
