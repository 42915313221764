export const environment = {
    production: false,
    dev: true,
    useCDN: false,
    version: '1.0.24',
    projectVersion: '3',
    firebase: {
        hostUrl:  'https://dev.easybox360.immersiontools.com',
        viewerUrl:  'https://dev.viewer.easybox.immersiontools.com/',
        apiKey: 'AIzaSyCi0bQwCeXPZsj-n35Po3fM2OleA441dKs',
        authDomain: 'videobroadcast-83d4e.firebaseapp.com',
        databaseURL: 'https://videobroadcast-83d4e.firebaseio.com',
        projectId: 'videobroadcast-83d4e',
        storageBucket: 'gs://videobroadcast-83d4e.appspot.com/',
        messagingSenderId: '148379843937',
        appId: '1:148379843937:web:01437a9a2be9ebc9',
        cdnURL: '',
        statisticsURL: 'https://videobroadcast-stats.europe-west1.firebasedatabase.app'
    },
    vimeo: {
        token: '2E10AAD2B253E7B8763A31C8FF19AE0014D2F73F370A712E625BDA60270DDC97',
        url: 'https://vimeoapi.easystudio360.tools',
    },
    stripe: {
        apiKey: 'pk_test_51MTjoIHbyPVjj8eHfLzEtn0MNmKSLVxXA647SRvhibGOsyFcbuAUz9HenSuUyeVCy4aB9QDAEIfHASN3VsvRNjn500MrR6CT3I',
        url: 'https://stripeapi.dev.easystudio360.tools'
    },
    recaptcha: {
        siteKey: '6Leu9T4lAAAAAFfX7MrBjM4bJCE6lCsKCVo6jrB5'
    },
    share: {
        userKey: 'RjnT0DOxffdAhrHAm55GFkLWP8M2',
        libraryKey: '-NS5PHXSDpjJKkYz4x6y',
        version: '3',
        duration: 7, //in days
    },
    newsletter: {
        key: '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',
        listId: 8,
    },
    settings: {
        lastlogin: 5, // in minutes
    },
    template: {
        libraryKey: '-McE6Jsj6c3b3yj7TMir',
        version: 3
    },
    examples: {
        userKey: 'RFUMc7ArWiPI38yM2zF8nxEwlnr1',
    },
    api_easystudio360: {
        url: 'https://dev.api.easystudio360.fr'
    }
};
