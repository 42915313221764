import { DatabaseService } from 'app/services/database.service';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ScenarioService } from './scenario.service';

import { RoomSize, Scene } from 'app/models/scenario/scene.model';
import { Event } from 'app/models/scenario/event.model';
import { Time } from 'app/models/scenario/time.model';
import { Point } from 'app/models/scenario/point.model';
import { Angle } from 'app/models/scenario/angle.model';


@Injectable({
    providedIn: 'root'
})
export class SceneService {

	/**
	 * Use to access and set the current scene in the editor
	 */

	private _scene: Scene;
	sceneSubject = new Subject<Scene>();
    private _indexScene: number;

    realMediaHeight = 0;
    realMediaWidth = 0;
    

	constructor(
		private _scenarioService: ScenarioService
	) {
		// Avoid getting an undefined scene
		if (!this._scene) {
			this._scene = new Scene("Error Key", "");
		}
	}

	// -----------------------------------------------------------------------------------------------------
	// @ Mutator methods
	// -----------------------------------------------------------------------------------------------------
	get scene(): Scene { return this._scene; }

	set scene(value: Scene) {
		this._scene = value;
		//console.log("setScene");
	//	this.justEmitScene();
	}

    get indexScene(): number { return this.indexScene; }
    set indexScene(value: number) {
		this._indexScene = value;
		//console.log("setSceneIndex");
	//	this.justEmitScene();
	}


	// -----------------------------------------------------------------------------------------------------
	// @ Private methods
	// -----------------------------------------------------------------------------------------------------

	/**
	 * Emit scene
	 */
    private emitScene(databaseService:DatabaseService=undefined, saveScenario = true) {
        if (saveScenario)
            this._scenarioService.uploadScenario(undefined);
        this.sceneSubject.next(this._scene);
    }

	// -----------------------------------------------------------------------------------------------------
	// @ Public methods
	// -----------------------------------------------------------------------------------------------------
	
	/**
	 * All actions on scene should pass through this service to emit scene when it changes
	 */

	/**
	 * Get scene is a trigger to emit scene and save
	 */
	getScene() {
		//console.log("getScene");
		this.justEmitScene();
	}

	/**
	 * Just emit scene
	 */
	justEmitScene() {
		this.sceneSubject.next(this._scene);
	}

	/**
	 * Get scene key
	 * 
	 * @returns Key string
	 */
	getSceneKey() : string {
		return this._scene.key;
	}

	/**
	 * Reset scene
	 * 
	 * Should be called when the user leaves the editor
	 */
	resetScene() {
		//console.log("resetScene");
		this._scene = new Scene("Error Key", "");
		this.justEmitScene();
	}


    changeSceneTitle(title:string)
    {
        //console.log("scenetitelze");
        if(this._scene.title!=title)
        {
            this._scene.title=title;   
            this._scenarioService.dbService.updateScene(this._scenarioService.scenario.key, this._indexScene,"Title",title);
			
        }
    }
	/**
	 * Change media key
	 * 
	 * @param key 
	 */
	changeMediaKey(key: string) {
		//console.log("changeMediaKey");
        if(this._scene.mediaKey!=key)
		{
            this._scene.mediaKey = key;
            this._scenarioService.dbService.updateScene(this._scenarioService.scenario.key, this._indexScene,"MediaKey",key);
        }
		
	}

    changeObject3DKey(key: string) {
		//console.log("changeObject3DKey");
		////console.log(key);
        if(this._scene.sceneOptions.obj3DKey!=key)
		{
            this._scene.sceneOptions.obj3DKey = key;
            this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"Obj3D",key,false);
        }
		this.justEmitScene();
	}

	changeAudioKey(key: string) {
		//console.log("changeAUdioKey");
//		//console.log(key);
        if(this._scene.sceneOptions.audioKey!=key)
        {
            this._scene.sceneOptions.audioKey = key;
            this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"AudioKey",key,false);
        }
		this.justEmitScene();
	}

	changeObj3DKey(key: string) {
		//console.log("changeAUdioKey");
//		//console.log(key);
        if(this._scene.sceneOptions.obj3DKey!=key)
        {
            this._scene.sceneOptions.obj3DKey = key;
            this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"Obj3D",key,false);
        }
		this.justEmitScene();
	}

	/**
	 * Get current media key
	 */
	getMediaKey() : string {
		if (this._scene != undefined || this._scene != null) {
			return this._scene.mediaKey;
		} else {
			return "";
		}
	}

	/**
	 * Set start position
	 * 
	 * @param {Point} point 
	 */
	setStartPosition(point: Point, emit = true) {
        if(this._scene.sceneOptions.startPosition.x!=point.x || this._scene.sceneOptions.startPosition.y!=point.y)
        {
            this._scene.sceneOptions.startPosition = point;
            this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"StartPosition",point,true);
        }
		if (emit) {
            //console.log("setStartPosition");
			this.justEmitScene();
		}
	}

	/**
	 * Set angle position
	 * 
	 * @param {Angle} angle 
	 */
	setStartPositionAngle(angle: Angle, emit = true) {
        if(this._scene.sceneOptions.startPositionAngle.alpha!=angle.alpha || this._scene.sceneOptions.startPositionAngle.beta!=angle.beta)
        {
            this._scene.sceneOptions.startPositionAngle = angle;
            this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"StartPositionAngle",angle,true);
        }
		if (emit) {
            //console.log("setStartPositionAngle");
			this.justEmitScene();
		}
	}

	/**
	 * Set time on scene option
	 * 
	 * @param {Time} time 
	 */
	setTimeOnSceneOption(time: Time) {

		if(this._scene.sceneOptions.timeOnScene!=time)
		{
            this._scene.sceneOptions.timeOnScene = time;
			this._scene.sceneOptions.timeOnScene.setSeconds(Math.trunc(this._scene.sceneOptions.timeOnScene.inSeconds()));
            this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"TimeOnScene",time,true);
        }
	}
	
	/**
	 * Set destination option
	 * 
	 * @param {string} destinationKey 
	 */
	setDestinationOption(destinationKey: string) {
		//console.log("setDestinationOption");
        if(this._scene.sceneOptions.destinationKey!=destinationKey)
        {
            this._scene.sceneOptions.destinationKey = destinationKey;
            this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"DestinationKey",destinationKey,false);
        }
	}

	/**
	 * Set btnPause option
	 * 
	 * @param {string} destinationKey 
	 */
	

    setTimeline(bool: boolean) {
        if(this._scene.sceneOptions.timeline!=bool)
        {
            this._scene.sceneOptions.timeline = bool;
            this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"TimeLine",bool,false);
        }
    }

    setLimit(limit: Angle) {
        //console.log("setLimit");
     //   if(this._scene.sceneOptions.limit.alpha!=limit.alpha || this._scene.sceneOptions.limit.beta!=limit.beta)
        {
            this._scene.sceneOptions.limit = new Angle(limit.alpha,limit.beta);
            this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"Limit",limit,true);
        }
	}

	setLimitV(limit: Angle) {
        //console.log("setLimit");
     //   if(this._scene.sceneOptions.limit.alpha!=limit.alpha || this._scene.sceneOptions.limit.beta!=limit.beta)
        {
            this._scene.sceneOptions.limitV = new Angle(limit.alpha,limit.beta);
            this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"LimitV",limit,true);
        }
	}

    setLoop(loop: boolean) {
        //console.log("setLoop");
        if(this._scene.sceneOptions.loop!=loop)
        {
            this._scene.sceneOptions.loop = loop;
            this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"Loop",loop,false);
        }
	}

    setVolume(volume: number) {
        //console.log("setVolume");
        if(this._scene.sceneOptions.volume!=volume)
        {
            this._scene.sceneOptions.volume = volume;
            this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"Volume",volume,false);
        }
	}

	setVidVolume(volume: number) {
        //console.log("setVidVolume");
        if(this._scene.sceneOptions.vidVolume!=volume)
        {
            this._scene.sceneOptions.vidVolume = volume;
            this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"VidVolume",volume,false);
        }
	}

	setRoomSize(roomSize: RoomSize) {
		//console.log("setRoomSize");
		if(this._scene.sceneOptions.roomSize!=roomSize)
		{
			this._scene.sceneOptions.roomSize = roomSize;
			this._scenarioService.dbService.updateSceneOptions(this._scenarioService.scenario.key, this._indexScene,"RoomSize",roomSize,false);
		}

	}
	
	/**
	 * Add event to events list
	 * 
	 * @param {Event} newEvent 
	 */
	addEvent(newEvent: Event) {
		//console.log("addEvent");
		this._scene.addEvent(newEvent);
		this._scenarioService.dbService.addSceneEvent(this._scenarioService.scenario.key, this._indexScene,this._scene.events.length-1,newEvent);
		this.justEmitScene();
	}
	
	InvertEvent(index1: number,modifiedEvent1: Event,index2: number,modifiedEvent2: Event) {

		this._scene.modifyEvent(modifiedEvent1);
		this._scene.modifyEvent(modifiedEvent2);

        this._scenarioService.dbService.updateSceneEvent(this._scenarioService.scenario.key, this._indexScene, index1, modifiedEvent1);
		this._scenarioService.dbService.updateSceneEvent(this._scenarioService.scenario.key, this._indexScene, index2, modifiedEvent2);

		this.justEmitScene();
	}
	/**
	 * Modify event at index in events list
	 * 
	 * @param {number} index 
	 * @param {Event} modifiedEvent
	 */
	modifyEvent(modifiedEvent: Event,emit:boolean= true) {
		//console.log("modifyEvent");
        //console.log(index);
        //console.log(modifiedEvent);
		const index=this._scene.modifyEvent(modifiedEvent);

        this._scenarioService.dbService.updateSceneEvent(this._scenarioService.scenario.key, this._indexScene, index, modifiedEvent);

		if(emit)
			this.justEmitScene();
	}

	/**
	 * Delete event in events list
	 * 
	 * @param event 
	 */
	deleteEvent(event: Event) {
		//console.log("deleteEvent");
		this._scene.deleteEvent(event);

		this.emitScene();
	}

	setEvents(events: Event[]){
		this._scene.events = events;
		this._scenarioService.dbService.updateSceneEvents(this._scenarioService.scenario.key, this._indexScene, this._scene.events);
	}

    addOpenBy(key: string, openBy: string)
    {
        ////console.log('addOpenBy');
        const index = this._scene.events.findIndex((ev)=> ev.key === key);
		const indexscene = this._scenarioService.scenario.scenes.findIndex((sc)=> sc.key === this._scene.key);
        ////console.log(this._scene.events[index]);
        if(index >= 0)
        {
            if(!this._scene.events[index].openBy.includes(openBy)){
                this._scene.events[index].openBy.push(openBy);
				this._scenarioService.dbService.updateSceneEvent(this._scenarioService.scenario.key,indexscene, index,this._scene.events[index]);
            }
            ////console.log(this._scene.events[index].openBy);
        }
    }

    removeOpenBy(key: string, openBy: string)
    {
        ////console.log('removeOpenBy');
        const index = this._scene.events.findIndex((ev)=> ev.key === key);
		const indexscene = this._scenarioService.scenario.scenes.findIndex((sc)=> sc.key === this._scene.key);
        if(index >= 0)
        {
            const i = this._scene.events[index].openBy.findIndex((k)=> k === openBy);
            if(i >= 0)
            {
                this._scene.events[index].openBy.splice(i,1);
				this._scenarioService.dbService.updateSceneEvent(this._scenarioService.scenario.key,indexscene, index,this._scene.events[index]);
            }
        }
    }

}

