import { JsonConvert, JsonObject, JsonProperty } from "json2typescript";
import { Content } from "../content.model";
import { Scenario } from "./scenario.model";


@JsonObject("ShareScenario")
export class ShareScenario {

    @JsonProperty("Key", String, true)
    private Key: string = "";
    @JsonProperty("From", String, true)
    private From: string = "";
   
    @JsonProperty("Story", Scenario, true)
    private Story: Scenario = new Scenario();
    @JsonProperty("Contents", [Content], true)
    private Contents: Content[] = [];
    @JsonProperty("Date", Number, true)
    private Date: number = 0;
    @JsonProperty("SizeOnStorage", Number, true)
    private SizeOnStorage: number = 0;
   
    
    
    constructor() {
       
    }
    set(key : string, from : string, story : Scenario, contents : Content[]){
        this.Key=key;
        this.From=from;
        this.Story=story;
        this.Date =  Date.now();
        let jsonConvert = new JsonConvert();
        var tmp = jsonConvert.deserializeArray(jsonConvert.serializeArray(contents,Content),Content);
        for(let index = 0; index < tmp.length; index++)
        {
            tmp[index].VimeoData = undefined;
        }
        this.Contents = tmp;
    }
    
    public get contents(): Content[] {
        return this.Contents;
    }
    public set contents(value: Content[]) {
        this.Contents = value;
    }
    public get key(): string {
        return this.Key;
    }
    public set key(value: string) {
        this.Key = value;
    }
    public get sizeOnStorage(): number {
        return this.SizeOnStorage;
    }
    public set sizeOnStorage(value: number) {
        this.SizeOnStorage = value;
    }
    public get from(): string {
        return this.From;
    }
    public set from(value: string) {
        this.From = value;
    }
    public get story(): Scenario {
        return this.Story;
    }
    public set story(value: Scenario) {
        this.Story = value;
    }
    public get date(): number {
        return this.Date;
    }
    public set date(value: number) {
        this.Date = value;
    }
}
