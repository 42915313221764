import { Injectable } from '@angular/core';

import { Scenario } from 'app/models/scenario/scenario.model';

@Injectable({
  	providedIn: 'root'
})
export class ScenariosService {
	
    scenarios: Scenario[] = new Array();

    constructor() {}

    // -----------------------------------------------------------------------------------------------------
    // @ GETTER
    // -----------------------------------------------------------------------------------------------------
    getScenarios() : Scenario[] {
        return this.scenarios;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ SETTER
    // -----------------------------------------------------------------------------------------------------
    setScenarios(scenarios: Scenario[]) {
        this.scenarios = scenarios;
        if(scenarios == undefined)
            return;
        //GET DOWNLOAD URL !
        for (let index = 0; index < scenarios.length; index++) {
            const project = scenarios[index];            
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    reset() {
        this.scenarios = new Array();
    }

    push(scenario: Scenario) {
        this.scenarios.push(scenario);
    }

    // createNewScenario() {

        // Project
        // if(this.scenarios==undefined)
		// 	this.scenarios = [];
		// var newScenario = new Scenario();
		// newScenario.init(firebase.database().ref().push().key,"");
        //newScenario.setKey(firebase.database().ref().push().key);
        //this.projects.push(newProject);
        // return newScenario.getKey();
    // }



    // deleteScenario(scenarioKey:string){
	// 	if(this.scenarios!=undefined)
    //     for (let i = 0; i < this.scenarios.length; i++) {
    //         if(scenarioKey == this.scenarios[i].getKey()){
    //             this.scenarios.splice(i,1);
    //             return;
    //         }
            
    //     }
    // }

    getScenario(scenarioKey: string) {
        for (let i = 0; i < this.scenarios.length; i++) {
            if (scenarioKey == this.scenarios[i].getKey()) return this.scenarios[i];
        }
        return null;
    }

}