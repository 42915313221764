/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Session } from 'inspector';
import { Any, JsonObject, JsonProperty } from 'json2typescript';

export enum DeviceType {
    'PC' = 0, 'Mobile' = 1, 'Tablet' = 2, 'Headset' = 3, 'Other' = 4
}

export enum AppType {
    'Web' = 0, 'Unity' = 1, 'Other' = 2
}

@JsonObject('EventStat')
export class EventStat {

    @JsonProperty('key', String, true)
	key : string = "";

    @JsonProperty('openingTime', Number, true)
	openingTime : number=0;

    @JsonProperty('closingTime', Number, true)
	closingTime : number=0;

    @JsonProperty('duration', Number, true)
	duration : number=0;
    
	@JsonProperty('data', Any, true)
	data : any = {};
	
}

@JsonObject('SceneStat')
export class SceneStat {

    @JsonProperty('key', String, true)
	key : string = "";

    @JsonProperty('openingTime', Number, true)
	openingTime : number=0;

    @JsonProperty('closingTime', Number, true)
	closingTime : number=0;

    @JsonProperty('duration', Number, true)
	duration : number=0;
    
	@JsonProperty('events', [EventStat], true)
	events : EventStat[] = [];
	
	
	
}

@JsonObject('SessionStat')
export class SessionStat {

    @JsonProperty('deviceDescription', String, true)
	deviceDescription : string = "";

	@JsonProperty('key', String, true)
	key : string = "";

    @JsonProperty('version', String, true)
	version : string = "";

    @JsonProperty('openingTime', Number, true)
	openingTime : number=0;

    @JsonProperty('closingTime', Number, true)
	closingTime : number=0;

    @JsonProperty('duration', Number, true)
	duration : number=0;

    @JsonProperty('device', Number, true)
	device : DeviceType = 0; 

    @JsonProperty('application', Number, true)
	application : AppType = 0; 

	@JsonProperty('scenes', [SceneStat], true)
	scenes : SceneStat[] = [];
	
	
}

@JsonObject('StoryStat')
export class StoryStat {
    
    @JsonProperty('key', String, true)
	key : string = "";
	@JsonProperty('sessions', [SessionStat], true)
	sessions : SessionStat[] = [];
	
	
}

@JsonObject('Statistics')
export class Statistics {

	@JsonProperty('stories', [StoryStat], true)
	stories : StoryStat[] = [];
	
	
	
}