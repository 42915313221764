import { Any, JsonObject, JsonProperty } from "json2typescript";


@JsonObject("AudioProperties")
export class AudioProperties {

        
    @JsonProperty("openingmedia", String, true)
    openingmedia: string;

    @JsonProperty("openingvolume", Number, true)
    openingvolume: number;
    
    constructor(media="",volume=100) 
    {
        this.openingmedia = media;
        this.openingvolume = volume;
    }

}

