import { JsonObject, JsonProperty,PropertyConvertingMode } from 'json2typescript';




@JsonObject('Variable')
export class Variable {
    @JsonProperty('name', String, true)
    public name : string = '';
    @JsonProperty('type', Number, true)
    public type: number=-1;
    @JsonProperty('stringvalues', [String], true)
    public stringvalues: string[] = [];
    @JsonProperty('initialvalue', String, true)
    public initialvalue: string="";
    @JsonProperty('actionvalue', String, true)
    public actionvalue: string="";


}