import { Injectable } from "@angular/core";

import { Subject } from "rxjs";

import { Download, DlType } from "app/models/download.model";
import { Content } from "app/models/content.model";
import { ShareScenario } from "app/models/scenario/sharescenario.model";
import { FunctionsService } from "./functions.service";
import { JsonConvert } from "json2typescript";

import { getAuth } from "firebase/auth";
import {
    getMetadata,
    ref,
    StorageReference,
    updateMetadata,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject,
    listAll,
    UploadMetadata,
} from "firebase/storage";
import { environment } from "environments/environment";
import { Diffusion } from "app/models/scenario/diffusion.model";

@Injectable({
    providedIn: "root",
})
export class StorageService {
    //STATIC
    static downloadList: Download[];

    static getIndex(contenKey: string): number {
        let index = -1;
        if (StorageService.downloadList != undefined) {
            //console.log("Get Index !");
            for (let i = 0; i < StorageService.downloadList.length; i++) {
                //console.log("i : "+i);
                if (StorageService.downloadList[i].ContentKey == contenKey) {
                    //console.log("Find !");

                    index = i;
                    break;
                }
            }
        }
        //console.log("Index : " +index);

        return index;
    }

    constructor() {}

    init(storage) {
        this.storage = storage;
    }

    _functionsService: FunctionsService;
    storage;
    filesize: 0;
    dlSubject = new Subject<Download[]>();
    //databaseService: DatabaseService;
    emitDownload() {
        this.dlSubject.next(StorageService.downloadList);
    }
    DownloadInProgress(): boolean {
        let inProgress = false;
        if (StorageService.downloadList != undefined)
            StorageService.downloadList.forEach((dl) => {
                if (dl.UploadProgress.inProgress) {
                    inProgress = true;
                    return false;
                }
            });
        return inProgress;
    }
    getProgress(contenKey: string): number {
        //console.log(StorageService.downloadList);
        if (StorageService.downloadList != undefined) {
            const dl = StorageService.downloadList.find(
                (dl) =>
                    dl.ContentKey == contenKey &&
                    dl.UploadProgress.lang === undefined,
            );
            const val = dl?.UploadProgress.inProgress
                ? dl?.UploadProgress.progress
                : -1;
            return val;
        }
        return -1;
    }

    getProgressLang(contenKey: string, lang: string): boolean {
        if (StorageService.downloadList != undefined) {
            for (const dl of StorageService.downloadList) {
                if (
                    dl.ContentKey === contenKey &&
                    dl.UploadProgress.lang === lang
                ) {
                    const val = dl.UploadProgress.inProgress
                        ? dl.UploadProgress.progress
                        : -1;

                    return val !== -1;
                }
            }
        }
        return false;
    }

    getPercentLang(contenKey: string, lang: string): number {
        if (StorageService.downloadList != undefined) {
            for (const dl of StorageService.downloadList) {
                if (
                    dl.ContentKey === contenKey &&
                    dl.UploadProgress.lang === lang
                ) {
                    return dl.UploadProgress.inProgress
                        ? dl.UploadProgress.progress
                        : -1;
                }
            }
        }
        return -1;
    }

    getSizeUploaded(contenKey: string): number {
        let progress = -1;
        if (StorageService.downloadList != undefined) {
            StorageService.downloadList.forEach((dl) => {
                if (dl.ContentKey == contenKey) {
                    progress = dl.UploadProgress.inProgress
                        ? dl.UploadProgress.size
                        : -1;
                    return progress;
                }
            });
        }
        return progress;
    }

    getUploadTotal(contenKey: string): number {
        let total = -1;
        if (StorageService.downloadList != undefined) {
            StorageService.downloadList.forEach((dl) => {
                if (dl.ContentKey == contenKey) {
                    total = dl.UploadProgress.inProgress ? dl.File.size : -1;
                    return total;
                }
            });
        }
        return total;
    }

    /*uploadFile(
        projectKey: string,
        environmentKey: string,
        interactionKey: string,
        contentKey: string,
        file: any,
        type: DlType,
        //uploadProgress: { key: string, inProgress: boolean, progress: number }
    ) {
        if (!file) {
            return;
        }
        if (StorageService.downloadList == undefined)
            StorageService.downloadList = [];
        let index =
            StorageService.downloadList.push(
                new Download(
                    projectKey,
                    environmentKey,
                    interactionKey,
                    contentKey,
                    file,
                    type,
                    0,
                ),
            ) - 1;

        return new Promise(async (resolve, reject) => {
            const user = getAuth().currentUser;
            let uploadRef: StorageReference;
            let filename =
                StorageService.downloadList[index].File.name;
            switch (type) {
                case DlType.Preview:
                    filename =
                        "preview." +
                        filename.substring(filename.lastIndexOf(".") + 1);

                    uploadRef = ref(
                        this.storage,
                        "Projects/" +
                            StorageService.downloadList[index].ProjectKey +
                            "/Environments/" +
                            StorageService.downloadList[index].EnvironmentKey +
                            "/Interactions/" +
                            StorageService.downloadList[index].InteractionKey +
                            "/Contents/" +
                            StorageService.downloadList[index].ContentKey +
                            "/Preview/" +
                            filename,
                    );
                    break;
                case DlType.Data:
                    filename =
                        "data." +
                        filename.substring(filename.lastIndexOf(".") + 1);
                    uploadRef = ref(
                        this.storage,
                        "Projects/" +
                            StorageService.downloadList[index].ProjectKey +
                            "/Environments/" +
                            StorageService.downloadList[index].EnvironmentKey +
                            "/Interactions/" +
                            StorageService.downloadList[index].InteractionKey +
                            "/Contents/" +
                            StorageService.downloadList[index].ContentKey +
                            "/Data/" +
                            filename,
                    );
                    break;
                case DlType.Logo:
                    filename =
                        "logo." +
                        filename.substring(filename.lastIndexOf(".") + 1);
                    uploadRef = ref(
                        this.storage,
                        "Projects/" +
                            StorageService.downloadList[index].ProjectKey +
                            "/Interface/Logo/" +
                            filename,
                    );
                    break;
                case DlType.Image:
                    filename =
                        "image." +
                        filename.substring(filename.lastIndexOf(".") + 1);
                    uploadRef = ref(
                        this.storage,
                        "Projects/" +
                            StorageService.downloadList[index].ProjectKey +
                            "/Interface/Image/" +
                            filename,
                    );
                    break;
                case DlType.Audio:
                    filename =
                        "audio." +
                        filename.substring(filename.lastIndexOf(".") + 1);
                    uploadRef = ref(
                        this.storage,
                        "Projects/" +
                            StorageService.downloadList[index].ProjectKey +
                            "/Interface/Audio/" +
                            filename,
                    );
                    break;
                default:
                    break;
            }
            if (uploadRef != null) {
                const meta = await getMetadata(uploadRef);
                meta.cacheControl = "no-cache";
                updateMetadata(uploadRef, meta);
                StorageService.downloadList[index].Task = uploadBytesResumable(
                    uploadRef,
                    StorageService.downloadList[index].File.arrayBuffer,
                );
            }
            StorageService.downloadList[index].Task.on(
                "state_changed",
                (snapshot) => {
                    index = StorageService.getIndex(contentKey);

                    StorageService.downloadList[
                        index
                    ].UploadProgress.inProgress = true;
                    StorageService.downloadList[index].UploadProgress.key =
                        StorageService.downloadList[index].ContentKey;

                    const progress =
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    //console.log('Upload is ' + progress + '% done');
                    StorageService.downloadList[index].UploadProgress.progress =
                        progress;
                    StorageService.downloadList[index].UploadProgress.size =
                        snapshot.bytesTransferred;

                    this.emitDownload();
                },
                function (error) {
                    console.log(error);
                    reject();
                },
                function () {
                    index = StorageService.getIndex(contentKey);

                    StorageService.downloadList[
                        index
                    ].UploadProgress.inProgress = false;
                    getDownloadURL(uploadRef).then((url) => {
                        const data = {
                            url: url,
                            name: StorageService.downloadList[index].File.name,
                            size: StorageService.downloadList[index].File.size,
                        };
                        resolve(data);
                        StorageService.downloadList.splice(index, 1);
                    });
                },
            );
        });
    }*/

    /**
     * Upload
     *
     * @param path
     * @param file
     *
     * @return Promise that resolve file download url
     */
    upload(path: string, file: any) {
        return new Promise((resolve, reject) => {
            const uploadRef = ref(this.storage, path);

            uploadBytesResumable(uploadRef, file).on(
                "state_changed",
                // Upload updates
                (snapshot) => {
                    console.log(snapshot);
                },
                // Error
                (error) => {
                    console.log(error);
                },
                // Done
                () => {
                    getDownloadURL(uploadRef).then((url) => {
                        resolve(url);
                    });
                },
            );
        });
    }

    /**
     * Upload
     *
     * @param path
     *
     * @return Promise that resolve download url from path
     */
    getDownloadUrl(path: string) {
        return new Promise((resolve, reject) => {
            getDownloadURL(ref(this.storage, path))
                .then((url) => {
                    resolve(url);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error);
                });
        });
    }

    /**
     * Delete
     *
     * @param path
     *
     * @return Promise that resolve when the file is deleted
     */
    delete(path: string) {
        return new Promise((resolve, reject) => {
            deleteObject(ref(this.storage, path)).then(
                () => {
                    resolve(undefined);
                },
                (reason) => {
                    console.log(reason);
                    console.log(new JsonConvert().serializeObject(reason));
                    resolve(reason);
                },
            );
        });
    }

    uploadFileV2(
        libraryKey: string,
        contentKey: string,
        file: File,
        type: DlType,
        content?: Content,
        code: string = undefined,
    ): Promise<any> {
        if (!file) {
            return;
        }
        //return this._vimeoService.uploadFile(libraryKey, contentKey, file, type, content).then((value)=>console.log(value));

        if (StorageService.downloadList == undefined) {
            StorageService.downloadList = [];
        }

        let index =
            StorageService.downloadList.push(
                new Download(
                    libraryKey,
                    null,
                    null,
                    contentKey,
                    file,
                    type,
                    0,
                    code,
                ),
            ) - 1;

            const download = StorageService.downloadList[index];


            try {
                let filename: string = file.name;
                const fileExtension = filename.substring(filename.lastIndexOf('.') + 1);
                filename = `${type.toLowerCase()}.${fileExtension}`;

                let uploadRef;
                switch (type) {
                    case DlType.Preview:
                    case DlType.Data:
                    case DlType.DataLow:
                        uploadRef = ref(this.storage, `${code ? `${code}/` : ''}Library/${download.ProjectKey}/Contents/${download.ContentKey}/${type}/${filename}`);
                        break;
                    case DlType.Logo:
                    case DlType.Image:
                    case DlType.Audio:
                        uploadRef = ref(this.storage, `Interface/${download.ProjectKey}/${type}/${filename}`);
                        break;
                    default:
                        return Promise.reject(new Error("Unsupported file type"));
                }

                if (!uploadRef) {
                    return Promise.reject(new Error("Failed to create storage reference"));
                }

                const metadata: UploadMetadata = {
                    cacheControl: "no-cache",
                    customMetadata: {
                        resize: `${content?.Resize ?? false}`,
                        autoPreview: `${content?.AutoPreview ?? false}`,
                        type: type,
                    },
                };

                download.Task = uploadBytesResumable(
                    uploadRef,
                    file,
                    metadata
                );

                return new Promise((resolve, reject) => {
                    download.Task.on(
                        "state_changed",
                        (snapshot) => {
                            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                            download.UploadProgress = {
                                inProgress: true,
                                lang: code,
                                key: download.ContentKey,
                                progress,
                                size: snapshot.bytesTransferred
                            };
                            //download.Filesize = snapshot.totalBytes;
                            this.emitDownload();
                        },
                        (error) => {
                            reject({ error, content });
                        },
                        async () => {
                            download.UploadProgress.inProgress = false;
                            try {
                                const url = await getDownloadURL(uploadRef);
                                const data = {
                                    url,
                                    name: file.name,
                                    size: download.File.size,
                                };
                                StorageService.downloadList.splice(index, 1);
                                resolve(data);
                            } catch (err) {
                                reject(err);
                            }
                        }
                    );
                });

            } catch (error) {
                return Promise.reject(error);
            }
    }

    async sendStory(
        sharedStory: ShareScenario,
        libraryKey: string,
        functionsService: FunctionsService,
    ) {
        console.log("sendStory");
        this._functionsService = functionsService;

        for (let index = 0; index < sharedStory.contents.length; index++) {
            const content = sharedStory.contents[index];
            const fromRef = ref(
                this.storage,
                "Library/" + libraryKey + "/Contents/" + content.key,
            );
            const toRef = ref(
                this.storage,
                "Share/" + sharedStory.key + "/Contents/" + content.key,
            );

            await this.CopyDirectory(fromRef, toRef, fromRef);
        }
    }

    async addStory(
        sharedStory: ShareScenario,
        libraryKey: string,
        functionsService: FunctionsService,
        callback,
    ) {
        console.log("sendStory");
        this._functionsService = functionsService;

        for (let index = 0; index < sharedStory.contents.length; index++) {
            const content = sharedStory.contents[index];
            const toRef = ref(
                this.storage,
                "Library/" + libraryKey + "/Contents/" + content.key,
            );
            const fromRef = ref(
                this.storage,
                "Share/" + sharedStory.key + "/Contents/" + content.key,
            );

            await this.CopyDirectory(fromRef, toRef, fromRef, callback);
        }
    }

    async addExample(
        diffusion: Diffusion,
        libraryKey: string,
        functionsService: FunctionsService,
        callback,
    ) {
        console.log("addExample");
        this._functionsService = functionsService;

        for (let key of diffusion.contentKeys) {
            if (key == undefined || key == null || key == "") {
                continue;
            }
            const toRef = ref(
                this.storage,
                "Library/" + libraryKey + "/Contents/" + key,
            );
            const fromRef = ref(
                this.storage,
                "Library/" + diffusion.libraryKey + "/Contents/" + key,
            );

            await this.CopyDirectory(fromRef, toRef, fromRef, callback);
        }
    }

    async CopyToMyLibrary(
        formContentKey: string,
        toContentKey: string,
        libraryKey: string,
        functionsService: FunctionsService,
        callback,
    ) {
        console.log("CopyToMyLibrary");
        this._functionsService = functionsService;
        const toRef = ref(
            this.storage,
            "Library/" + libraryKey + "/Contents/" + toContentKey,
        );
        const fromRef = ref(
            this.storage,
            "Library/" +
                environment.template.libraryKey +
                "/Contents/" +
                formContentKey,
        );
        console.log(fromRef);
        console.log(toRef);
        await this.CopyDirectory(fromRef, toRef, fromRef, callback);
    }

    async CopyDirectory(
        fromRef: StorageReference,
        toRef: StorageReference,
        reference: StorageReference,
        callback = undefined,
    ) {
        const dirs = await listAll(reference).then(function (res) {
            return res.prefixes;
        });
        //console.log(dirs);
        for (let index = 0; index < dirs.length; index++) {
            const folderRef = dirs[index];
            await this.CopyDirectory(fromRef, toRef, folderRef, callback);
        }
        const files = await listAll(reference).then(function (res) {
            return res.items;
        });
        //console.log(files);
        for (let index = 0; index < files.length; index++) {
            const itemRef = files[index];

            //console.log("*********************");

            const from =
                fromRef.fullPath.substring(
                    0,
                    fromRef.fullPath.indexOf("Library"),
                ) +
                itemRef.fullPath.substring(
                    0,
                    itemRef.fullPath.lastIndexOf("/"),
                );
            const filename = itemRef.fullPath.substring(
                itemRef.fullPath.lastIndexOf("/") + 1,
            );
            const end = itemRef.fullPath.substring(
                itemRef.fullPath.lastIndexOf("/"),
            );
            const middle = itemRef.fullPath.substring(
                itemRef.fullPath
                    .substring(0, itemRef.fullPath.length - end.length)
                    .lastIndexOf("/"),
            );
            const to =
                toRef.fullPath +
                middle.substring(0, middle.length - end.length); //itemRef.fullPath.substring(itemRef.fullPath.lastIndexOf('/')/*-fromRef.fullPath.length*/);

            const split = to.split("/");
            const contentKey = to.startsWith("Library/")
                ? split[split.length - 2]
                : undefined;
            const excludeFiles = [
                "data_editor",
                "data_low",
                "data_med",
                "data_high",
                "preview_auto",
            ];
            let exclude = false;
            for (let index = 0; index < excludeFiles.length; index++) {
                if (filename.includes(excludeFiles[index])) {
                    exclude = true;
                    break;
                }
            }
            if (!exclude) {
                //copy
                await this._functionsService
                    .copyFile(from, to, filename)
                    .then(async (data) => {
                        if (data.data.error != undefined) {
                        } else {
                            if (contentKey !== undefined && callback) {
                                callback(contentKey);
                            }
                        }
                    })
                    .catch((error) => {
                        console.log("Error !");
                        console.log(error);
                    });
            }
        }
    }

    async deleteFolderContents(path, contentKey = null) {
        console.log("deleteFolderContents : " + path);
        if (contentKey != null && StorageService.downloadList != undefined) {
            for (let i = 0; i < StorageService.downloadList.length; i++) {
                //console.log(StorageService.downloadList[i].ContentKey)
                if (StorageService.downloadList[i].ContentKey == contentKey) {
                    //console.log("Cancel DL !");
                    StorageService.downloadList[i].Task.cancel();
                    StorageService.downloadList.splice(i, 1);
                }
            }
        }
        const reference = ref(this.storage, path);

        await listAll(reference)
            .then((dir) => {
                dir.items.forEach((fileRef) => {
                    this.deleteContent(reference.fullPath, fileRef.name);
                });
                dir.prefixes.forEach((folderRef) => {
                    this.deleteFolderContents(folderRef.fullPath);
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    async deleteFolderProject(path) {
        //console.log(contentKey);

        const reference = ref(this.storage, path);

        await listAll(reference)
            .then((dir) => {
                dir.items.forEach((fileRef) => {
                    this.deleteContent(reference.fullPath, fileRef.name);
                });
                dir.prefixes.forEach((folderRef) => {
                    this.deleteFolderContents(folderRef.fullPath);
                });
            })
            .catch((error) => {
                console.log(error);
            });
    }

    private deleteContent(path: any, fileName: any) {
        deleteObject(ref(this.storage, path + "/" + fileName));
    }

    async RemoveInterface(key: string) {
        await this.deleteFolderProject("Interface/" + key);
    }

    async calculateSize(strRef: string, reference: StorageReference = null) {
        let size = 0;
        //console.log(reference);
        //console.log(strRef);
        let first = false;
        if (reference == null) {
            first = true;
            reference = ref(this.storage, strRef);
        }

        const dirs = await listAll(ref(reference)).then(function (res) {
            return res.prefixes;
        });

        for (let index = 0; index < dirs.length; index++) {
            const folderRef = dirs[index];
            size += await this.calculateSize("", folderRef);
            //console.log(size);
        }
        const files = await listAll(ref(reference)).then(function (res) {
            return res.items;
        });
        for (let index = 0; index < files.length; index++) {
            const itemRef = files[index];
            const meta = await getMetadata(itemRef).then(function (metadata) {
                return metadata;
            });
            size += meta["size"] / Math.pow(10, 9);
        }
        //this.addFileSize(size);
        return size;
    }

    async getSizes(content: Content,libraryKey: string,lang = undefined): Promise<number[]>{
        //       console.log('-----------------------------------getSizes---------------------------');
               const sizes = [];
               const splits = content.Data.split('.');
               
               const ext = content.GenWebp?'webp':splits[splits.length-1];
       
               const promises: Promise<any>[] = [];
               if(content.DataLowUrl!=='')
               {
                   promises.push(getMetadata(ref(this.storage,
                       ((lang === undefined)? '': ('/'+lang+'/'))
                       +'Library/'
                       + libraryKey
                       + '/Contents/'
                       + content.key +
                       '/Data/data_low.'
                       + ext
                   )));
                   /*.then(function(metadata){
                       return metadata;
                   });
                   sizes.push(meta['size']);*/
               }
               else
               {
                   promises.push(undefined);
                   //sizes.push(-1);
               }
               //console.log('1');
               if(content.DataMedUrl!=='')
               {
                   /*let meta = await firebase.storage().ref('Library/' + libraryKey + '/Contents/' + content.key + '/Data/data_med.' + ext).getMetadata().then(function(metadata){
                       return metadata;
                   });
                   sizes.push(meta['size']);*/
                   promises.push(getMetadata(ref(this.storage,
                       ((lang === undefined)? '': ('/'+lang+'/'))
                       + 'Library/'
                       + libraryKey
                       + '/Contents/'
                       + content.key
                       + '/Data/data_med.'
                       + ext
                   )));
       
               }
               else
               {
                   promises.push(undefined);
                   //sizes.push(-1);
       
               }
               //console.log('2');
               if(content.DataHighUrl!=='')
               {
                   promises.push(getMetadata(ref(this.storage,
                       ((lang === undefined)? '': ('/'+lang+'/'))
                       +'Library/'
                       + libraryKey
                       + '/Contents/'
                       + content.key
                       + '/Data/data_high.'
                       + ext
                   )));
                   /*let meta = await firebase.storage().ref('Library/' + libraryKey + '/Contents/' + content.key + '/Data/data_high.' + ext).getMetadata().then(function(metadata){
                       return metadata;
                   });
                   sizes.push(meta['size']);*/
               }
               else
               {
                   promises.push(undefined);
                   //sizes.push(-1);
               }
               //console.log('3');
               //console.log(promises);
               await Promise.all([
                   promises[0],
                   promises[1],
                   promises[2],
               ]).then((value) => {
                   //console.log('value');
                   //console.log(value);
                   for (const val of value){
                       if(val!==undefined) {
                           sizes.push(val['size']);
                       }else{
                           sizes.push(-1);
                       }
                   }
               });
               //console.log('4');
       
        //       console.log('-----------------------------------getSizes 4---------------------------');
               sizes.push(content.DataSize);
               //console.log('sizes');
          //     console.log(sizes);
       
               return sizes;
           }

    async getMetadata(content: Content, libraryKey: string): Promise<any> {
        const ext = content.Data.split(".")[1];
        return getMetadata(
            ref(
                this.storage,
                "Library/" +
                    libraryKey +
                    "/Contents/" +
                    content.key +
                    "/Data/data." +
                    ext,
            ),
        );
    }

    async checkIfFileExists(path: string, fileName: string): Promise<boolean> {
        //console.log('checkIfFileExists');
        const split = path.split("/");
        let index = 0;
        let tmp = split[0];

        /*console.log(split);
        console.log(split.length);
        console.log(index);*/

        while (index < split.length) {
            //console.log(index);
            //console.log(JSON.stringify(this.storage)+ "");
            //console.log(tmp);
            const reference = ref(this.storage, tmp);
            const dirs = await listAll(reference).then(function (res) {
                return res.prefixes;
            });
            //console.log(dirs);
            let found = false;
            for (let index2 = 0; index2 < dirs.length; index2++) {
                const dir = dirs[index2];
                //console.log(dir);
                if (dir.name === split[index]) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                return false;
            }
            index++;

            const tmpIndex = index % split.length;
            tmp += "/" + split[tmpIndex];
        }
        const files = await listAll(ref(this.storage, path)).then(
            function (res) {
                return res.items;
            },
        );
        //console.log(fileName);
        //console.log(files);
        for (let index = 0; index < files.length; index++) {
            const itemRef = files[index];
            //console.log(itemRef);
            if (itemRef.name === split[index]) {
                return true;
            }
        }
        return false;
    }
}
