import { JsonObject, JsonProperty, JsonConverter, JsonCustomConvert, Any } from "json2typescript";
import { environment } from "environments/environment";
import { DataSource } from '@angular/cdk/table';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { CollectionViewer } from '@angular/cdk/collections';
import { DatabaseService } from 'app/services/database.service';
import { catchError, finalize } from 'rxjs/operators';

@JsonObject("PagePref")
export class PagePref {
	@JsonProperty("ItemPerPage", Number, true)
	ItemPerPage : number = 10;
	@JsonProperty("TagFolded", Boolean, true)
	TagFolded : boolean = false;
}

@JsonObject("UserPref")
export class UserPref {

	@JsonProperty("TagFolded", Boolean, true)
	TagFolded : boolean = false;
	@JsonProperty("Diffusion", PagePref, true)
	Diffusion : PagePref = new PagePref();
	@JsonProperty("Lang", String, true)
	Lang : string = "fr";
	


	constructor() {
		
	}
}

