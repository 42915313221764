/* eslint-disable @typescript-eslint/naming-convention */
import { JsonProperty } from 'json2typescript';
import { Condition, Conditions } from './condition.model';
import { AnimationType, Event, EventType, TitlePosition, TitleType } from './event.model';
import { Time } from './time.model';
import { AudioProperties } from './audio.model';
import { Variable } from './variables.model';

/**
 * Form
 */
 export class FormEvent extends Event {

    constructor(
        key?: string,
        titlePoi?: string,
        start?: Time,
        end?: Time,
        hotspotScale?: number,
        display?: boolean,
        titleType?: TitleType,
        iconCustom?: boolean,
        iconImageKey?: string,
        animate?: boolean,
        animationtype?: AnimationType,
        nbMaxOpenInScene?: number,
        nbMaxOpen?: number,
        openBy?:string[],
        titlePosition?: TitlePosition,
        form?: FormInput[],
        formTitle?: string,
        formDescription?: string,
        conditions?: Conditions,
        auduio?:AudioProperties,
        hotspotvisible?: boolean,
        hotspotvisibleOnOver?: boolean,
        openVariable?: Variable,
        closeVariable?: Variable
    ){
        super(key, titlePoi, start, end, hotspotScale, display, titleType,titlePosition, iconCustom, iconImageKey, animate,animationtype,nbMaxOpenInScene,nbMaxOpen,openBy,conditions,auduio,hotspotvisible,hotspotvisibleOnOver, openVariable,closeVariable);

        this.icon = 'es_qcm';
        this.type = EventType.Form;
        if(form === undefined){
            form = [];
        }
        else //if(!Array.isArray(form)){
            /*if(form instanceof Object)*/{
            let index = 0;
            const tmp: FormInput[]= [];
            while(form[index]!==undefined)
            {
                tmp.push(new FormInput(form[index].type,form[index].key,form[index].name,(form[index].required!==undefined)&&form[index].required,(form[index].recordifchanged!==undefined)&&form[index].recordifchanged));
                index++;
            }
            form = tmp;
        }

        this.properties = {
            Form: form,
            Title: (formTitle === undefined)?'' : formTitle,
            Description: (formDescription === undefined)?'' : formDescription
        };
    }
}
export class FormInput{
    @JsonProperty('type', String, true)
    type: InputType;
    @JsonProperty('name', String, true)
    name: string;
    @JsonProperty('required', Boolean, true)
    required: boolean;
    @JsonProperty('key', String, true)
    key: string;
    @JsonProperty('value', String, true)
    value: any;
    @JsonProperty('recordIfChanged', Boolean, true)
    recordifchanged: boolean;

    constructor(type: InputType=InputType.Email,key: string ='None',name: string='',required=false,recordifchanged=false){
        this.type = type;
        this.key = key;
        this.name = name;
        this.required = required;
        this.recordifchanged = recordifchanged;
    }

}

export enum InputType {
    Email='Email',
    Phone='Phone',
    Int='Int',
    Float='Float',
    // eslint-disable-next-line id-blacklist
    String='String',
    Text='Text',
    Bool='Bool',
    Score='Score',
    Variable='Variable',
    Date='Date',
    DateEnd='DateEnd',
}